.container {
    display: block;
    padding-top: 67px;
}

.questionImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.loadingTitle {
    composes: typography from './commonStyles.module.css';
    color: #323232;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 210px;
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.3px;
}

.questionText {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #74787B;
    padding-bottom: 10px;
}

.thankYou {
    composes: typography from './commonStyles.module.css';
    display: block;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.14994px;
    color: #13BCB4;
    text-align: center;
    padding-top: 10px;
}

.loadingStatus {
 composes: typography from './commonStyles.module.css';
 display: none;
 font-size: 12px;
 line-height: 146.48%;
 color: rgba(0, 0, 0, 0.4);
 text-align: center;
}

.selectionChips {
    display: -webkit-inline-flex;
    display: inline-flex;
    position: relative;
    left: 19px;
}

.skip {
    composes: typography from './commonStyles.module.css';
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
    position: relative;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    margin-top: 30px;
    font-size: 12px;
    margin-right: 28px;
    cursor: pointer;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    color: #13BCB4;
}