.container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.linkNote {
  composes: typography from './commonStyles.module.css';
  display: none !important;
  font-size: 10px;
  line-height: 18px;
  color: #979797;
  text-align: center;
  display: block;
  margin-top: 15px;
}

.ctaContent {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 44px;
}

.ctaTitle {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.244445px;
  color: #323232;
}

.ctaText {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.ctaLink {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 195px;
  height: 45px;
  background: #13BCB4;
  border-radius: 37.2352px;
  margin-top: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;

}

.ctaLink:hover {
  background: #0F9790;
}

.ctaLinkText {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  padding-left: 10px;
  color: #FFFFFF;
}

.linkWrapper {
  display: block;
  padding-top: 34px;
}