/*@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);*/

.overlayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 28.8vw;
  min-width: 480px;
  max-width: 480px;
  min-height: 662px;
  /*height: 78vh;*/
  max-height: 820px;
  background: #FFFFFF;
  box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
}

.modalContent {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.icon {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.image {
    display: block;
    margin-right: auto;
    margin-left: auto;
    border-radius: 16px;
    width: 30%;
}

.header {
composes: typography from './commonStyles.module.css';
font-weight: bold;
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
margin-bottom: 21px;
}

.answerText {
composes: typography from './commonStyles.module.css';
font-size: 14.5562px;
line-height: 25px;
letter-spacing: 0.419063px;
color: #74787B;
max-width: 407px;
margin-left: 49px;
display: block;
margin-left: 49px;
margin-right: auto;
}

.sendButton {
  composes: typography from './commonStyles.module.css';
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #13BCB4;
  border-radius: 51.7581px;
  width: 247px;
  height: 52.6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  outline: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
  cursor: pointer;
}

.thankYouContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.heartIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.thankYouTitle {
composes: typography from './commonStyles.module.css';
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
}

.footer {
  composes: typography from './commonStyles.module.css';
  display: block;
  text-align: center;
  font-size: 14.5562px;
  line-height: 25px;
  letter-spacing: 0.419063px;
  color: #74787B;
  margin-top: 18px;
}

.offerContainer {
  background: #E7F8F7;
  border-radius: 0px;
  width: 100%;
  height: 188px;
}

.offerContent {
  display: block;
  margin-top: 30px;
  margin-left: 60px;
  position: relative;
  top: 5px;
}

.offerHeader {
composes: typography from './commonStyles.module.css';
font-size: 18px;
line-height: 27px;
letter-spacing: 0.3px;
color: #323232;
}

.planFeatureContainer {
  display: flex;
  margin-top: 10px;
  align-items: baseline;
}

.planFeatureText {
composes: typography from './commonStyles.module.css';
font-weight: 300;
font-size: 14px;
line-height: 28px;
letter-spacing: 0.627384px;
color: #323232;
margin-left: 15px;
}
