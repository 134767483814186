.arrowContainer {
  transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  display: block;
  position: absolute;
  left: 950px;
  top: 630px;
  transform: rotate(180deg);
  z-index: 1000;
}

.arrow {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 10px;
  background-color: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
  animation: arrow 700ms linear infinite;
  background: #13BCB4;
  z-index: 3000;
}

.arrow::after, .arrow::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  background-color:#13BCB4;
}

.arrow::after {
  top: -12px;
  transform: rotate(45deg);
}

.arrow::before {
  top: 12px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  transform: rotate(-45deg);
}

@keyframes arrow {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}