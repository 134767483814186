.selectedTab {
composes: typography from './commonStyles.module.css';
color: #13BCB4;
font-size: 12px;
line-break: 23px;
line-height: 40px;
cursor: pointer;
border-bottom: 2px solid rgb(0 133 255 / 100%);
outline: none;
}

.selectedTabForm {
  composes: selectedTab;
  border-bottom: 4px solid rgb(19,188,180,1);
  font-weight: 500;
  }

.tab {
composes: typography from './commonStyles.module.css'; 
color: #13BCB4;
font-size: 12px;
line-break: 23px;
line-height: 40px;
cursor: pointer;
outline: none;
width: fit-content;
}

.tab::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #13BCB4;
  transition: all 0.2s ease;
}

.tab:hover::after {
  width: 100%;
}