.container {
flex-grow: 1;
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 19px;
height: 51vh;
max-width: 23vw;
max-width: 23vw;
background: white;
display: none;
}

@media screen and (min-width: 768px) {
  .container {
    margin-top: 0px;
    margin-left: 50px;
    display: flex;
  }
}

@media screen and (min-width: 769px) {
  .container {
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
  }
}

.innerContainer {
  height: 100%;
  width: -webkit-fill-available;
}

@media screen and (min-width: 769px) {
  .innerContainer {
    z-index: 1000;
  }
}

  .transferButtonContinaer {
    height: 4.5em;
    padding: 0 1.25em;
    position: relative;
    padding-top: 10px;
    }

    .downloadViewTitle {
      composes: typography from './commonStyles.module.css';
      display: inline-block;
      position: absolute;
      width: 230px;
      height: 22px;
      left: 74px;
      top: 200px;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      color: #000000;
    }

    .downloadViewCallToAction {
      composes: typography from './commonStyles.module.css';
      position: relative;
      padding-top: 100px;
      font-size: 14px;
      line-height: 16px;
      left: 80px;
      text-decoration: underline;
      display: inline-block;
      align-items: center;
      color: #000000;
      }

      .spinner {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
