.container {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  justify-items: center;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .container {
    flex-wrap: nowrap;
  }
}

.homePageContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;
}

@media screen and (min-width: 768px) {
  .homePageContainer {
    flex-flow: row nowrap; /* required */
  }
}

.title {
  composes: typography from './commonStyles.module.css';
  font-weight: 900;
  justify-content: center;
  flex-grow: 3;
  font-size: 16px;
  line-height: 54px;  
  text-align: center;
  letter-spacing: 12px;
  text-transform: uppercase;
  color: #FFFFFF;
  }

  @media screen and (min-width: 321px) {
    .title {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 768px) {
    .title {
      font-size: 34px;
    }
  }

  .desc {   
    flex-grow: 1;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
   /* line-height: 74px;*/
    text-align: center;
    letter-spacing: -0.244445px;
    color: #FFFFFF;


  }

  @media screen and (min-width: 768px) {
    .desc {
      font-size: 46px;
    }
  }

  .img {
    position: relative;
    flex-grow: 1;
    max-width: 351px;
    /*margin-right: 100px;*/
    max-height: 450px;
    display: none;
  }

  @media screen and (min-width: 768px) {
    .img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .oneLiner {
    margin: 0;
    flex-grow: 1;
    margin-bottom: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 40px;
    display: block;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  @media screen and (min-width: 768px) {
    .oneLiner {
      font-size: 18px;
    }
  }

  .mobileButton {
    display: none;
    padding: 9px 15px;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 2px;
    background-color: #fff;
    font-family: Ubuntu, Helvetica, sans-serif;
    color: #000;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    height: fit-content;
    margin-top: 20px;
  }
  
  .mobileButton {
    display: inline-block;
  }

  @media screen and (min-width: 768px) {
    .mobileButton {
      display: none;
    }
  }

  .header {
    display: flex;
    justify-items: flex-start;
    width: 986px;
    background-color: #FAFAFA;
    height: 30px;
    border-radius: 4px;
  }

  .poweredByWrapper {
    display: block;
    margin-top: 10px;
  }

  .poweredByText {
    composes: typography from './commonStyles.module.css';
    margin-top: 20px;
    display: block;
    outline: none;
    border: none;
    border-radius: 29px;
    background-color: transparent;
    font-size: 14px;
    color:white;
    text-align: center;
  }

  .shareFilesButton {
    composes: typography from './commonStyles.module.css';
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    outline: none;
    border: none;
    border-radius: 29px;
    background-color: transparent;
    font-size: 14px;
    color:white;
    text-align: center;
  }

  .linkNote {
    composes: typography from './commonStyles.module.css';
    font-size: 12.5px;
    line-height: 24px;
    display: -webkit-flex;
    display: block;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    color: #013348;
    margin: 8px 0px;
    margin-top: 25px;
  }

  .mainContentInnerWrapper {
    display: block;
    flex-grow: 3;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .mainContentInnerWrapper {
      box-sizing: border-box;
      width: 100%;
      flex: none;
      margin-left: -100%; /* required */
  }
  }

  .contentWrapper {
    text-align: center;
    margin-top: 10vh;
  }

  @media screen and (min-width: 768px) {
    .contentWrapper {
      margin-top: 50px;
  }
  }

  .mainContentOuterWrapper {
    display: block;
  }

  @media screen and (min-width: 768px) {
    .mainContentOuterWrapper {
      
  }
  }

  .formWrapper {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 768px) {
    .formWrapper {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex: none;
  }
  }

  .publishedTitle {
    composes: typography from './commonStyles.module.css';
    display: block;
    text-align: center;
    margin-top: 120px;
    font-size: 56px;
    line-height: 74px;
    letter-spacing: -0.244445px;
    color: #323232;
  }

  .startCustomizing {
    composes: typography from './commonStyles.module.css';
    background: #13BCB4;
    cursor: pointer;
    border-radius: 37.2352px;
    width: 231px;
    height: 65px;
    border: none;
    outline: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .startCustomizing:hover{
    background: #0F9790;
  }

  .upsellCtaTitle {
    composes: typography from './commonStyles.module.css';
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.244445px;
    color: #323232;
  }

  .transferLimitExceededTitle {
    composes: upsellCtaTitle;
    color: #EA3989;

  }
  
  .upsellCtaText {
    composes: typography from './commonStyles.module.css';
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }

  .validationTooltipContent {
    display: flex;
    justify-content: center;
  }

  .tooltipValidationIcon {
    margin-right: 16px;
  }

  .tooltipValidationText {
    composes: typography from './commonStyles.module.css';
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.422139px;
    color: #74787B;
  }

  .bellIcon {

  }