.completionScreenRoot {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.completionScreenContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.completionScreenTitle {
composes: typography from './commonStyles.module.css';
font-weight: bold;
font-size: 56px;
line-height: 74px;
text-align: center;
letter-spacing: -0.244445px;
color: #FFFFFF;
}

.completionScreenDescription {
margin-top: 39px;
composes: typography from './commonStyles.module.css';
font-size: 28px;
line-height: 40px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;
color: #FFFFFF;
}

.button {
width: 225.47px;
height: 50px;
background: #13BCB4;
border-radius: 41.2172px;
margin-top: 20px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
outline: none;
border: none;
}

.buttonContent {
composes: typography from './commonStyles.module.css';
display: flex;
align-items: center;
justify-content: center;
font-weight: 600;
font-size: 19.9249px;
line-height: 30px;
display: flex;
align-items: center;
color: #FFFFFF;
}