.overlayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
  z-index: 2000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 28.8vw;
  min-width: 414px;
  max-width: 430px;
  min-height: 620px;
  /*height: 78vh;*/
  max-height: 820px;
  background: #FFFFFF;
  box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
}

.modalContent {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.iconStyle {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
composes: typography from './commonStyles.module.css';
font-size: 30.5806px;
line-height: 46px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
margin-top: 25px;
display: block;
}

.subTitle {
composes: typography from './commonStyles.module.css';
font-weight: 600;
font-size: 19.4604px;
line-height: 25px;
text-align: center;
letter-spacing: 0.417009px;
color: #13BCB4;
margin-top: 22px;
display: block;
}

.featureList {
width: 100%;
height: 43%;
background: #EEF6FD;
border-radius: 0px;
margin-top: 37px;
display: block;
}

.fetaureContainer {
  margin-top: 8px;
  margin-left: 10%;
}

.featureText {
composes: typography from './commonStyles.module.css';
font-size: 17px;
line-height: 35px;
letter-spacing: 0.589982px;
color: #74787B;
}

.confirmationContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.confirmationText {
composes: typography from './commonStyles.module.css';
font-size: 16.8736px;
line-height: 25px;
text-align: center;
letter-spacing: 0.42184px;
color: #979797;
text-align: center;
display: block;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
}

.button {
  composes: typography from './commonStyles.module.css';
  border-radius: 51.7581px;
  width: 41%;
  height: 7%;
  min-height: 52px;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  align-items: center;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.upgradePlan {
  composes: button;
  background: #13BCB4;
  color: white;
  border: none;
}

.downgradePlan {
  composes: button;
  background: white;
  border: 2px solid #13BCB4;
  box-sizing: border-box;
  color: #13BCB4;
}