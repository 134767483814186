.userDetails {
  padding-left: 25px;
  }

  .chipsList {
    margin: 0;
    overflow: auto;
    padding: .625em 0 0 .125em;
   /* max-height: 30px;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 194px;
    left: 20px;
    width: 260px;
    z-index: 1000;*/
}