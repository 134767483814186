.container {
    position: relative;
    border-bottom: 1px solid rgb(0, 0, 0, 0.15);
    height: 100%;
    background-color: #FAFAFA;
    }

    .containerEmptyState {
      composes: container;
      background-color: white;
      }
    
    .addFilelabel{
       composes: typography from './commonStyles.module.css';
        font-size: 22px;
        line-height: 23px;
        color: #000000;
        text-align: center;
        display: block;
        /*padding-top: 30px;*/
        padding-bottom: 30px;
        cursor: pointer;
      }

      @media (max-width: 768px) {
        .addFilelabel {
          top: 74px;
        }
      } 
    
      .selectFolderLabel{
        composes: typography from './commonStyles.module.css';
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        position: relative;
        padding-top: 2px;
        left: 6px;
        height: 15px;
        margin-bottom: 40px;
        font-size: 16px;
        text-decoration-line: underline;
        color: #13BCB4;
        cursor: pointer;
        outline: none;
        z-index: -1;
      }

    /*  @media (max-width: 768px) {
        .selectFolderLabel {
          display: none;
        }
      } */

      .addFilesIcon{
        position: absolute;
        width: -webkit-fill-available;
        height: 0px;
        left: 0px;
        top: 55px;
        left: 70px;
        cursor: pointer;
      }

    /*  @media (max-width: 768px) {
        .addFilesIcon {
          top: 47px;
          left: 80px; 
        }
      } */

      .addMoreFilesTitle {
        composes: typography from './commonStyles.module.css';
        composes: inputTextColor from './commonStyles.module.css';
        position: relative;
        outline: none;
        width: 137px;
        height: 22px;
        left: 0px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        margin-left: 15px;
      }

      .filesAddedTitle {
        composes: typography from './commonStyles.module.css';
        left: 40px;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        opacity: 0.4;
        position: relative;
        width: -webkit-fill-available;
        margin-bottom: 10px;
        bottom: 30px;
      }

      .filesToDownload {
        composes: typography from './commonStyles.module.css';
        display: inline-block;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        -webkit-align-items: center;
        align-items: center;
        color: #000000;
        width: -webkit-fill-available;
        padding-top: 40px;
        height: 100%;
      }

      .filesToDownloadList {
        overflow: auto;
        overflow-x: hidden;
      }

      .viewWrapper{
       border-bottom: 1px solid rgb(0, 0, 0, 0.05);
      }

      .addMoreFilesWrapper{
        height: 50px;
        cursor: pointer;
        outline: none;
      }

      .filesList {
        overflow: auto;
        margin: 0;
        position: relative;
        top: 10px;
        overflow-x: hidden;
        /*padding-top: 10px;*/
      }

      .addYouFilesTitle {
        composes: typography from './commonStyles.module.css';
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        color: #13BCB4;
        margin: 0;
      }

      .downloadButton {
        composes: typography from './commonStyles.module.css';
        padding: 0 1.25em;
        background: #191B48;
        border-radius: 2px;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 12px;
        cursor: pointer;
      }

      .shareButton {
        composes: typography from './commonStyles.module.css';
        padding: 0 1.25em;
        background: #191B48;
        border-radius: 2px;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 12px;
        cursor: pointer;
        margin-top: 40px;
        outline: none;
      }

      .linkMessage {
        composes: typography from './commonStyles.module.css';
        height: 80px;
        width: 80%;
        resize: none;
        outline: none;
        font-size: 16px;
        letter-spacing: 0.422143px;
        color: #323232;
        box-sizing: border-box;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border: none;
        position: relative;
        top: 21px;
      }

      .divider {
        border-top: 1.415px solid #E6E9EF;
        position: relative;
        top: 21px;
    }

    .formTitleContainer {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .formTitleWrapper {
      display: flex;
      height: 0px;
      margin-bottom: 8px;
      border: 1.49207px dashed #C4C4C4;
      box-sizing: border-box;
      border-radius: 12.0639px;
      width: 86%;
      height: 109px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      position: relative;
      top: 20px;
    }

    .formTitleInnerContainer {
      display: flex;
      align-items: baseline;
      justify-content: center;
    }

    .uploadIcon {
      margin-right: 11px;
      position: relative;
      top: 4px;
    }

    .dragAndDropText {
      composes: typography from './commonStyles.module.css';
      font-size: 14px;
      line-height: 21px;
      display: block;
      align-items: center;
      text-align: center;
      color: #C4C4C4;
      position: relative;
      left: 14px;
      top: 4px;
    }
