.heading2Xl {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
  }
  
  .headingXl {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
  }
  
  .headingLg {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
  }
  
  .headingMd {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .borderCircle {
    border-radius: 9999px;
  }
  
  .colorInherit {
    color: inherit;
  }
  
  .padding1px {
    padding-top: 1px;
  }
  
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .listItem {
    margin: 0 0 1.25rem;
  }
  
  .lightText {
    color: #999;
  }

  .dropZone {
    align-items: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    position: absolute;
    width: -webkit-fill-available;
    bottom: 0px;
    top: 0;
  }

  .mainWrapper {
    height: 100vh;
   /* display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 7vh;*/
  }

  @media screen and (min-width: 768px) {
    .mainWrapper {
      margin-top: unset;
    }
  }

  .root {
    height: 100%;
    outline: none;
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    transition: background-color 0.2s linear;
  }

  .poweredByText {
    composes: typography from '../components//commonStyles.module.css';
    font-size: 15px;
    letter-spacing: 0.444977px;
    color:white;
    display: block;
    outline: none;
    border: none;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .poweredByText {
      text-align: unset;
    }
  }

  .poweredByLink {
    composes: poweredByText;
    text-decoration-line: underline;
    padding: 0;
    margin: 0;
  }

  .poweredByContainer{
    display: block;
    margin-top: auto;
    position: relative;
    padding-top: 10px;
    
  }

  @media screen and (min-width: 768px) {
    .poweredByContainer {
      bottom: 6vh;
      padding-top: unset;
      margin-left: 50px;
    }
  }

  .loaderText {
    composes: typography from '../components//commonStyles.module.css';
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    position: relative;
    top: 14px;
  }
  
  .loaderTextSubHeadline {
    composes: typography from '../components//commonStyles.module.css';
    composes: loaderText;
    font-size: 12px;
  }

  .mainGridContainer {
    height: 100%;
  }