.container {
    box-sizing: border-box;
   /* height: 100%;*/
    overflow-x: hidden; 
    text-align: center;
}

.errorIcon {
 width: 200px;
 margin-left: 40px;
 height: 150px;
}

.errorTitle {
    composes: typography from './commonStyles.module.css';
    display: inline-block;
    margin-top: 60px;
    align-items: center;
    line-height: 16px;
    color: #74787B;
   }

   .errorTitleSmall {
    composes: errorTitle;
    font-size: 13px;
   }

.errorContent {
composes: typography from './commonStyles.module.css';
margin-top: 30px;
font-size: 14px;
line-height: 16px;
display: inline-block;
align-items: center;

color:#323232;
}