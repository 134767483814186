@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);

.overlayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 840px;
  min-height: calc(min(95%,620px));
  /*height: 78vh;*/
  max-height: 629px;
  background: #FFFFFF;
  box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
  display: flex;
  overflow: hidden;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.rightBlock {
  display: flex;
  flex-direction: column;
  width: 41%;
  background: #E7F8F7;
  align-items: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
}

.iconStyle {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title {
composes: typography from './commonStyles.module.css';
font-weight: 600;
font-size: 30px;
line-height: 45px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
display: block;
}

.subTitle {
composes: typography from './commonStyles.module.css';
font-size: 14px;
line-height: 27px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
margin-top: 17px;
}

.featuresContainer {
  display: block;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 33px;
}

.featuresTitle {
composes: typography from './commonStyles.module.css';
font-size: 24px;
line-height: 36px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
display: block;
width: max-content;
}

.featureList {
width: 100%;
height: 43%;
background: #EEF6FD;
border-radius: 0px;
margin-top: 20px;
display: block;
}

.fetaureContainer {
  margin-top: 8px;
}

.featureText {
composes: typography from './commonStyles.module.css';
font-size: 13px;
line-height: 35px;
letter-spacing: 0.589982px;
color: #74787B;
}

.footer {
  margin-top: auto;
  display: block;
  position: absolute;
  bottom: 40px;
}

.alreadyHaveAccount {
composes: typography from './commonStyles.module.css';
font-size: 16px;
line-height: 18px;
text-align: center;
letter-spacing: 0.3px;
color: #74787B;
display: flex;
position: relative;
left: 24px;
}

.confirmationContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.confirmationText {
composes: typography from './commonStyles.module.css';
font-size: 16.8736px;
line-height: 25px;
text-align: center;
letter-spacing: 0.42184px;
color: #979797;
text-align: center;
display: block;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
}

.button {
  composes: typography from './commonStyles.module.css';
  border-radius: 51.7581px;
  width: 41%;
  height: 7%;
  min-height: 52px;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  align-items: center;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.upgradePlan {
  composes: button;
  background: #13BCB4;
  color: white;
  border: none;
}

.downgradePlan {
  composes: button;
  background: white;
  border: 2px solid #13BCB4;
  box-sizing: border-box;
  color: #13BCB4;
}

.loaderText {
  composes: typography from './commonStyles.module.css';
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #FFFFFF;
  display: block;
  position: relative;
  top: 14px;
}

.loaderTextSubHeadline {
  composes: typography from './commonStyles.module.css';
  composes: loaderText;
  font-size: 12px;
}

.whySignupWrapper {
  display: block;
}

.whySignupContainer {
  margin-bottom: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 329px;
  height: 97px;
  background: #E7F8F7;
  border-radius: 16px;
}

.whySignupContainerMinimized {
  composes: whySignupContainer;
  background: white;
  margin-bottom: 12px;
  position: relative;
  right: 22px;
  height: 57px;
}

.whySignupTextContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.whySignup {
composes: typography from './commonStyles.module.css';
font-size: 14px;
line-height: 17px;
letter-spacing: 0.275331px;
text-decoration-line: underline;
color: #13BCB4;
position: relative;
left: 7px;
}

.whySignupTooltipContainer {
  /*display: block;
  position: relative;
  bottom: 30px;
  left: 77px;*/
  display: block;
  position: relative;
  left: 33px;
  z-index: 100;
}

.whySignupTooltip {
/*width: 240px;
height: 81px;*/
width: 256px;
height: 84px;
background: #FFFFFF;
box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
border-radius: 16px;
}

.whySignupTooltipPointer {
  width: 0;
  height: 0;
  background: transparent;
  position: relative;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ffff;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 18px;
}

.whySignupTooltipText {
  composes: typography from './commonStyles.module.css';
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.348485px;
  color: #74787B;
  display: block;
  position: relative;
  top: 10px;
  }

.whySignupText {
composes: typography from './commonStyles.module.css';
font-size: 13px;
line-height: 21px;
text-align: center;
letter-spacing: 0.348485px;
color: #74787B;
margin-top: 6px;
}

.whySignupTextHidden {
  display: none;
}

.formGif {
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-radius: 16px;
  width: 79%;
  margin-top: 86px;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .overlayContainer {
    background-color: rgba(0, 0, 0, 0);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    filter: drop-shadow(0px 71px 71px rgba(0, 0, 0, 0.6400691));
  }
}

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .overlayContainer {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }
