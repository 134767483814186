@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);
.addFilesForm_container__1Pcr5 {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow-x: hidden; 
}
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  -webkit-transition: stroke-dashoffset 0.5s ease 0s;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

.fileItem_fileName__2QOTP {
    width: 200px;
    height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 146.48%;
    text-align: left;
    margin-bottom: 8px;
}

.fileItem_fileNameInProgress__2gEsp {
  /*color: rgba(0,0,0,0.4);*/
}

.fileItem_fileSize__zOPcA {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #BBBDC0;
    width: -webkit-fill-available;
    padding-bottom: 5px;
    height: 10px;
    margin: 0px;
}

.fileItem_uploadProgress__1K61- {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.4;
  width: -webkit-fill-available;
  padding-bottom: 5px;
  height: 10px;
  margin-bottom: 0px;
  padding-top: 4px;
}

.fileItem_listItemStyle__1Oag5 {
    width: 280px;
    height: 19px;
    display: table;
    right: 40px;
    padding-right: 10px;
    position: relative;
    overflow-wrap: break-word;
    border-bottom: 1px solid #E6E9EF;
    background-color: #FAFAFA;
    padding-bottom: 5px;
}

.fileItem_listItemContent__3piOH {
  margin-left: 22.5px;
}

.fileItem_li__2Wz5n {
    white-space: nowrap;
    display: table-row;
    width: 240px;
    height: 19px;
    left: 23px;
    top: 26px;
    position: absolute;
}

.fileItem_downloadLink__2smvc {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  outline: none;
  position: relative;
  left: 14px;
  top: 15px;
}

.fileItem_spinner__2FdaE {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 240px;
  top: 2vh;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

 .fileItem_CircularProgressbar__LQKhH {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
     left: 210px;
     position: absolute;
     top: 15px;
     width: 64px;
     height: 64px;
    /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
    vertical-align: middle;
  }
  
  .fileItem_CircularProgressbar__LQKhH .fileItem_CircularProgressbar-path__3M3JY {
    stroke: #3e98c7;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  
  .fileItem_CircularProgressbar__LQKhH .fileItem_CircularProgressbar-trail__123tg {
    stroke: #d6d6d6;
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
    stroke-linecap: round;
  }
  
  .fileItem_CircularProgressbar__LQKhH .fileItem_CircularProgressbar-text__3_fDW {
    fill: #3e98c7;
    font-size: 32px;
    dominant-baseline: middle;
    text-anchor: middle;
    font-weight: 600;
  }
  
  .fileItem_CircularProgressbar__LQKhH .fileItem_CircularProgressbar-background__3Rre5 {
    fill: #d6d6d6;
  }
  
  /*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
  .fileItem_CircularProgressbar__LQKhH.fileItem_CircularProgressbar-inverted__1oLs3 .fileItem_CircularProgressbar-background__3Rre5 {
    fill: #3e98c7;
  }
  
  .fileItem_CircularProgressbar__LQKhH.fileItem_CircularProgressbar-inverted__1oLs3 .fileItem_CircularProgressbar-text__3_fDW {
    fill: #fff;
  }
  
  .fileItem_CircularProgressbar__LQKhH.fileItem_CircularProgressbar-inverted__1oLs3 .fileItem_CircularProgressbar-path__3M3JY {
    stroke: #fff;
  }
  
  .fileItem_CircularProgressbar__LQKhH.fileItem_CircularProgressbar-inverted__1oLs3 .fileItem_CircularProgressbar-trail__123tg {
    stroke: transparent;
  }


.fileDownloadItem_fileName__2HyjA {
    width: 200px;
    height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 146.48%;
    text-align: left;
}

.fileDownloadItem_fileSize__36Eie {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    opacity: 0.4;
    width: -webkit-fill-available;
    padding-bottom: 5px;
    height: 10px;
    margin: 0px;
}

.fileDownloadItem_uploadProgress__2xffE {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.4;
  width: -webkit-fill-available;
  padding-bottom: 5px;
  height: 10px;
  margin: 0px;
}

.fileDownloadItem_listItemStyle__48vmp {
    width: -webkit-fill-available;
    height: 19px;
    left: -20px;
    display: table;
    right: 20px;
    padding-right: 10px;
    position: relative;
    overflow-wrap: break-word;
    border-bottom: 1px solid rgb(0, 0, 0, 0);
}

.fileDownloadItem_listItemStyleInProgress__lXoTD {
  opacity: 0.3;
}

.fileDownloadItem_li__2gG1v {
    white-space: nowrap;
    display: table-row;
    width: 240px;
    height: 19px;
    left: 23px;
    top: 26px;
    position: absolute;
}

.fileDownloadItem_spinner__3R_R6 {
  position: absolute;
  left: 200px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.fileDownloadItem_downloadLink__Ulz0H {
  font-size: 12px;
  padding-top: 16px;
  margin: 0;
  outline: none;
  right: 18px;
}

.fileDownloadItem_downloadIcon__3alk- {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  outline: none;
  position: relative;
  left: 14px;
  top: 15px;
}

.fileDownloadItem_spinner__3R_R6 {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 214px;
  top: 24px;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

 .fileDownloadItem_CircularProgressbar__MViNg {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
     left: 210px;
     position: absolute;
     top: 15px;
     width: 64px;
     height: 64px;
    /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
    vertical-align: middle;
  }
  
  .fileDownloadItem_CircularProgressbar__MViNg .fileDownloadItem_CircularProgressbar-path__nSGZj {
    stroke: #3e98c7;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  
  .fileDownloadItem_CircularProgressbar__MViNg .fileDownloadItem_CircularProgressbar-trail__1gS9j {
    stroke: #d6d6d6;
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
    stroke-linecap: round;
  }
  
  .fileDownloadItem_CircularProgressbar__MViNg .fileDownloadItem_CircularProgressbar-text__yJOSr {
    fill: #3e98c7;
    font-size: 32px;
    dominant-baseline: middle;
    text-anchor: middle;
    font-weight: 600;
  }
  
  .fileDownloadItem_CircularProgressbar__MViNg .fileDownloadItem_CircularProgressbar-background__22LVq {
    fill: #d6d6d6;
  }
  
  /*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
  .fileDownloadItem_CircularProgressbar__MViNg.fileDownloadItem_CircularProgressbar-inverted__2QnR2 .fileDownloadItem_CircularProgressbar-background__22LVq {
    fill: #3e98c7;
  }
  
  .fileDownloadItem_CircularProgressbar__MViNg.fileDownloadItem_CircularProgressbar-inverted__2QnR2 .fileDownloadItem_CircularProgressbar-text__yJOSr {
    fill: #fff;
  }
  
  .fileDownloadItem_CircularProgressbar__MViNg.fileDownloadItem_CircularProgressbar-inverted__2QnR2 .fileDownloadItem_CircularProgressbar-path__nSGZj {
    stroke: #fff;
  }
  
  .fileDownloadItem_CircularProgressbar__MViNg.fileDownloadItem_CircularProgressbar-inverted__2QnR2 .fileDownloadItem_CircularProgressbar-trail__1gS9j {
    stroke: transparent;
  }


.disabledOverlay_container__3wKLY {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EEF6FD;
    opacity: 0.5;
    width: 280px;
    height: 174px;
    z-index: 50;
    position: absolute;
    border-radius: 19px;
  }
.addFilesView_container__26B5x {
    position: relative;
    border-bottom: 1px solid rgb(0, 0, 0, 0.15);
    height: 100%;
    background-color: #FAFAFA;
    }

    .addFilesView_containerEmptyState__XygAz {
      background-color: white;
      }
    
    .addFilesView_addFilelabel__1pOXw{
        font-size: 22px;
        line-height: 23px;
        color: #000000;
        text-align: center;
        display: block;
        /*padding-top: 30px;*/
        padding-bottom: 30px;
        cursor: pointer;
      }

      @media (max-width: 768px) {
        .addFilesView_addFilelabel__1pOXw {
          top: 74px;
        }
      } 
    
      .addFilesView_selectFolderLabel__eoY7r{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: -moz-fit-content;
        width: fit-content;
        position: relative;
        padding-top: 2px;
        left: 6px;
        height: 15px;
        margin-bottom: 40px;
        font-size: 16px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline;
        color: #13BCB4;
        cursor: pointer;
        outline: none;
        z-index: -1;
      }

    /*  @media (max-width: 768px) {
        .selectFolderLabel {
          display: none;
        }
      } */

      .addFilesView_addFilesIcon__2kKso{
        position: absolute;
        width: -webkit-fill-available;
        height: 0px;
        left: 0px;
        top: 55px;
        left: 70px;
        cursor: pointer;
      }

    /*  @media (max-width: 768px) {
        .addFilesIcon {
          top: 47px;
          left: 80px; 
        }
      } */

      .addFilesView_addMoreFilesTitle__2Z1Ui {
        position: relative;
        outline: none;
        width: 137px;
        height: 22px;
        left: 0px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        margin-left: 15px;
      }

      .addFilesView_filesAddedTitle__3I3cp {
        left: 40px;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #000000;
        opacity: 0.4;
        position: relative;
        width: -webkit-fill-available;
        margin-bottom: 10px;
        bottom: 30px;
      }

      .addFilesView_filesToDownload__3pWkM {
        display: inline-block;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        color: #000000;
        width: -webkit-fill-available;
        padding-top: 40px;
        height: 100%;
      }

      .addFilesView_filesToDownloadList__2kWHd {
        overflow: auto;
        overflow-x: hidden;
      }

      .addFilesView_viewWrapper__3v-e8{
       border-bottom: 1px solid rgb(0, 0, 0, 0.05);
      }

      .addFilesView_addMoreFilesWrapper__2LFTH{
        height: 50px;
        cursor: pointer;
        outline: none;
      }

      .addFilesView_filesList__2NOo7 {
        overflow: auto;
        margin: 0;
        position: relative;
        top: 10px;
        overflow-x: hidden;
        /*padding-top: 10px;*/
      }

      .addFilesView_addYouFilesTitle__2rn8e {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        display: flex;
        align-items: center;
        color: #13BCB4;
        margin: 0;
      }

      .addFilesView_downloadButton__1sOQQ {
        padding: 0 1.25em;
        background: #191B48;
        border-radius: 2px;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 12px;
        cursor: pointer;
      }

      .addFilesView_shareButton___kOrX {
        padding: 0 1.25em;
        background: #191B48;
        border-radius: 2px;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 12px;
        cursor: pointer;
        margin-top: 40px;
        outline: none;
      }

      .addFilesView_linkMessage__26C51 {
        height: 80px;
        width: 80%;
        resize: none;
        outline: none;
        font-size: 16px;
        letter-spacing: 0.422143px;
        color: #323232;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border: none;
        position: relative;
        top: 21px;
      }

      .addFilesView_divider__1Baj9 {
        border-top: 1.415px solid #E6E9EF;
        position: relative;
        top: 21px;
    }

    .addFilesView_formTitleContainer__2XOvD {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .addFilesView_formTitleWrapper__13UXC {
      display: flex;
      height: 0px;
      margin-bottom: 8px;
      border: 1.49207px dashed #C4C4C4;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      border-radius: 12.0639px;
      width: 86%;
      height: 109px;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      position: relative;
      top: 20px;
    }

    .addFilesView_formTitleInnerContainer__XiIQT {
      display: flex;
      align-items: baseline;
      justify-content: center;
    }

    .addFilesView_uploadIcon__18wTf {
      margin-right: 11px;
      position: relative;
      top: 4px;
    }

    .addFilesView_dragAndDropText__kVCRH {
      font-size: 14px;
      line-height: 21px;
      display: block;
      align-items: center;
      text-align: center;
      color: #C4C4C4;
      position: relative;
      left: 14px;
      top: 4px;
    }

.varificationForm_container__q3UbG {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow-x: hidden; 
}

.varificationForm_verifyEmailTitle__3443q {
font-size: 18px;
line-height: 23px;
color: #000000;
position: relative;
bottom: 70px;
}

.varificationForm_lockStyle__3vR9u {
   left: 64px;
   position: relative;
   bottom: 50px;
   width: 150px;
   text-align: center;
}

.varificationForm_sendAgainText__25jSJ {
    display: inline;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

.varificationForm_varificationText__2vACI {
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    position: relative;
    text-align: center;
    bottom: 80px;
} 

.varificationForm_errorText__3UrYb {
    max-width: 220px;
    font-size: 10px;
    line-height: 16px;
    display: flex;
    color: red;
    text-align: center;
    position: relative;
    left: 55px;
    bottom: 30px;
} 

.varificationForm_input__m7nr0 {
 width: 241px;
 height: 43px;
 background: #FFFFFF;
 border: 1px solid #D0D4D9;
 -webkit-box-sizing: border-box;
         box-sizing: border-box;
 border-radius: 12px;
 position: relative;
 bottom: 125px;
 text-align: center;
}

.varificationForm_inputplaceHolder__1uTeF {
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

display: flex;
align-items: center;

color: #000000;

opacity: 0.4;

}

.resendVerificationView_container__3drYO {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow-x: hidden; 
}

.resendVerificationView_verifyEmailTitle__1YPhR {
font-size: 16px;
line-height: 23px;
color: #000000;
position: relative;
bottom: 70px;
}

.resendVerificationView_lockStyle__3FxHu {
   left: 64px;
   position: relative;
   bottom: 50px;
   width: 150px;
   text-align: center;
}

.resendVerificationView_sendAgainText__KiE2z {
    display: inline;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

.resendVerificationView_varificationText__2Ygd- {
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    position: relative;
    text-align: center;
    bottom: 70px;
} 

.resendVerificationView_errorText__3IjKZ {
    max-width: 220px;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    color: red;
    text-align: center;
    position: relative;
    top: 10px;
    left: 55px;
} 

.resendVerificationView_input__2cq4i {
    width: 241px;
    height: 43px;
    background: #FFFFFF;
    border: 1px solid #D0D4D9;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 12px;
    position: relative;
    bottom: 50px;
    padding-bottom: 20p;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-break: 23px;
}

.resendVerificationView_inputplaceHolder__3Sw2T {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    opacity: 0.4;
}

.floatinglabelInput_floatinglabelInput__2CdTI {
    font-size: 14px;
    line-break: 23px;
    left: 40px;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    outline: none;
    border: none;
    margin-bottom: 4px;
    width: 100%;
}

.floatinglabelInput_floatinglabelInputReadOnly__1HiHk {
    color: rgb(0,0,0, .5);
}

.floatinglabelInput_label__3GsVt {
    font-size: 14px;
    line-break: 23px;
    left: 40px;
    opacity: 0.4;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    pointer-events: none;
    position: relative;
    -webkit-transform: translate(0, 26px) scale(1);
            transform: translate(0, 26px) scale(1);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    display: flex;
    flex-direction: column;
    left: 0px;
    bottom: 13.5px;
}


.floatingLabeTextArea_floatingLabeTextArea__2Q81Q {
    font-size: 14px;
    line-break: 23px;
    /*position: absolute;*/
    left: 40px;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    outline: none;
    border: none;
    width: 100%;
}

.floatingLabeTextArea_label__nowHm {
    font-size: 14px;
    line-break: 23px;
    /*position: absolute;*/
    left: 40px;
    opacity: 0.4;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    pointer-events: none;
    position: relative;
    -webkit-transform: translate(0, 26px) scale(1);
            transform: translate(0, 26px) scale(1);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    display: flex;
    flex-direction: column;
    left: 0px;
    bottom: 13.5px;
    padding-bottom: 10px;
}


.chip_item__3BIaF {
    background-color: #d4d5d6;
    font-size: 14px;
    border-radius: 30px;
    height: 24px;
    padding: 0 6px 0 .4rem;
    display: inline-block;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    opacity: 0.8;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
    text-align: -webkit-right;
    letter-spacing: 0.5px;
    outline: none;
  }
  
  .chip_button__1UQ1V {
    background-color: white;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    outline: none;
  }
.moreRecipientsChip_item__fvVeK {
    cursor: pointer;
    outline: none;
    left: 5px;
    background-color: #d4d5d6;
    font-size: 12px;
    letter-spacing: 0.5px;
    padding: 0 6px 0 .4rem;
    border-radius: 30px;
    height: 24px;
    padding: 0 4px 0 0.3rem;
    display: inline-block;
   /* -webkit-align-items: center; */
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    opacity: 0.8;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
    text-align: -webkit-right;
  }
  
  .moreRecipientsChip_button__dh751 {
    background-color: white;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
  }
.recipientList_recipientsList__3q3dS {
    min-height: 46px;
    font-size: 14px;
    line-break: 23px;
    left: 40px;
    outline: none;
    border: none;
    margin-bottom: 4px;
    width: 100%;
    border-bottom: 1px solid rgb(0, 0, 0, 0.15);
}


.mailDetails_userDetails__z65X0 {
  padding-left: 25px;
  }

  .mailDetails_chipsList__1ikYK {
    margin: 0;
    overflow: auto;
    padding: .625em 0 0 .125em;
   /* max-height: 30px;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 194px;
    left: 20px;
    width: 260px;
    z-index: 1000;*/
}
.loadingView_container__7lOyz {
    display: block;
    padding-top: 67px;
}

.loadingView_questionImage__1CZCb {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.loadingView_loadingTitle__3PPTz {
    color: #323232;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 210px;
    margin-bottom: 6px;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.3px;
}

.loadingView_questionText__3Eo1Q {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #74787B;
    padding-bottom: 10px;
}

.loadingView_thankYou__MoTuU {
    display: block;
    font-size: 16px;
    line-height: 12px;
    letter-spacing: 0.14994px;
    color: #13BCB4;
    text-align: center;
    padding-top: 10px;
}

.loadingView_loadingStatus__etKl_ {
 display: none;
 font-size: 12px;
 line-height: 146.48%;
 color: rgba(0, 0, 0, 0.4);
 text-align: center;
}

.loadingView_selectionChips__1x_Qf {
    display: inline-flex;
    position: relative;
    left: 19px;
}

.loadingView_skip__31LeG {
    display: flex;
    margin-left: auto;
    position: relative;
    justify-content: flex-end;
    margin-top: 30px;
    font-size: 12px;
    margin-right: 28px;
    cursor: pointer;
    width: min-content;
    color: #13BCB4;
}
.linkBox_linkBoxContainer__g3nPv {
    display: flex;
    justify-content: center;
}

.linkBox_linkText__1uT4- {
    font-size: 12px;
    line-height: 31px;
    letter-spacing: 0.425357px;
    color: #CBCFD2;
    padding-left: 34px;
}

.linkBox_linkBox__1fEt0 {
    width: 184px;
    height: 34px;
    display: flex;
    background: #FFFFFF;
    border: 1.42296px solid #E1E1E1;
    outline: none;
    background: url(/_next/static/media/linkIcon.064b90c44f9736a94f54c64888ac9975.svg) no-repeat right center;
    background-position-x: 12px;
    background-position-y: 50%;
}

.linkBox_linkBox__1fEt0::selection {
    background:rgb(0,0,0,0.6); /* WebKit/Blink Browsers */
  }
  .linkBox_linkBox__1fEt0::-moz-selection {
    background:rgb(0,0,0,0.6); /* Gecko Browsers */
  }

  .linkBox_linkBoxCopied__3fKMX {
    color: #13BCB4;
    padding-left: unset;
    padding-right: 38px;
    text-align: right;
}

.linkBox_copyLinkButton__3gSS6 {
    width: 39px;
    height: 34px;
    background: #13BCB4;
    border-radius: 5.89091px;
    cursor: pointer;
    border: none;
    position: relative;
    right: 4px;
    outline: none;
    -webkit-transition: -webkit-transform ease-in 0.1s, -webkit-box-shadow ease-in 0.25s;
    transition: -webkit-transform ease-in 0.1s, -webkit-box-shadow ease-in 0.25s;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s, -webkit-transform ease-in 0.1s, -webkit-box-shadow ease-in 0.25s;
}

.linkBox_copyLinkButton__3gSS6:before, .linkBox_copyLinkButton__3gSS6:after {
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    -webkit-transition: all ease-in-out 0.5s;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }

.linkBox_copyLinkButton__3gSS6:before {
    display: none;
    top: -75%;
    background-image: -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, transparent 20%, #13BCB4 20%, transparent 30%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%);
    background-image: radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 20%, #13BCB4 20%, transparent 30%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%);
    background-size: 20% 20%, 20% 20%, 25% 25%, 30% 30%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
    -webkit-animation: linkBox_topBubbles__Z7o7P ease-in-out 0.75s infinite;
            animation: linkBox_topBubbles__Z7o7P ease-in-out 0.75s infinite;
  }
  
  .linkBox_copyLinkButton__3gSS6:after {
    display: none;
    top: -75%;
    background-image: -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%);
    background-image: radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
    -webkit-animation: linkBox_bottomBubbles__VLkRH ease-in-out 0.75s infinite;
            animation: linkBox_bottomBubbles__VLkRH ease-in-out 0.75s infinite;
  }

  .linkBox_copyLinkButtonAnimate__2Hwc1 {
  }

  .linkBox_copyLinkButtonAnimate__2Hwc1:before, .linkBox_copyLinkButtonAnimate__2Hwc1:after {
    display: block;
  }

.linkBox_linkBoxContent__3L7El {
    display: flex;
    margin-left: 22px;
}


@-webkit-keyframes
linkBox_topBubbles__Z7o7P {  0% {
 background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
}
 50% {
 background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
}
 100% {
 background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
 background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
}
}


@keyframes
linkBox_topBubbles__Z7o7P {  0% {
 background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
}
 50% {
 background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
}
 100% {
 background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
 background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
}
}

@-webkit-keyframes
linkBox_bottomBubbles__VLkRH {  0% {
 background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
}
 50% {
 background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
}
 100% {
 background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
 background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
}
}

@keyframes
linkBox_bottomBubbles__VLkRH {  0% {
 background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
}
 50% {
 background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
}
 100% {
 background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
 background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
}
}
.uploadFinishedView_container__1LNWd {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
   /* height: 100%;*/
    overflow-x: hidden; 
    text-align: center;
}

.uploadFinishedView_doneImage__2fhmo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 43px;
}

.uploadFinishedView_summaryTitle__wnfe4 {
    display: inline-block;
    align-items: center;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #323232;
    padding-top: 18px;
}

.uploadFinishedView_summaryText__2qdsB {
    display: inline-block;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #74787B;
    padding-top: 8px;
}

.uploadFinishedView_downloadLink__3LiGM {
    font-size: 14px;
    line-height: 16px;
    left: 2.5rem;
    text-decoration: underline;
    display: contents;
    align-items: center;
    color: #000000;
    }

    .uploadFinishedView_recipientsList__3sTYS {
        display: grid;
        border: 1px solid #E6E9EF;
        border-right: 0;
        border-left: 0;
    }

    .uploadFinishedView_listItem__3xXC3 {
        display: inline-flex;
        min-height: 46px;
        border-bottom: 1px solid #E6E9EF;
    }

    .uploadFinishedView_listItemContent__BUffT{
        padding-top: 6px;
    }

    .uploadFinishedView_listItemEmail__2Rk3u {
    display: contents;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.3px;    
    color: #74787B;
    }

    .uploadFinishedView_listItemLast__3aCTI {
        border-bottom: 0;
    }

    .uploadFinishedView_linkBoxContainer__10kUU {
        display: flex;
        justify-content: center;
        padding-top: 48px;
    }

    .uploadFinishedView_linkText__HZS1J {
        font-size: 12px;
        line-height: 31px;
        letter-spacing: 0.425357px;
        color: #CBCFD2;
        padding-left: 34px;
    }

    .uploadFinishedView_linkBox__1TYQm {
        width: 184px;
        height: 34px;
        display: flex;
        background: #FFFFFF;
        border: 1.42296px solid #E1E1E1;
        outline: none;
        background: url(/_next/static/media/linkIcon.064b90c44f9736a94f54c64888ac9975.svg) no-repeat right center;
        background-position-x: 12px;
        background-position-y: 50%;
    }

    .uploadFinishedView_linkBox__1TYQm::selection {
        background:rgb(0,0,0,0.6); /* WebKit/Blink Browsers */
      }
      .uploadFinishedView_linkBox__1TYQm::-moz-selection {
        background:rgb(0,0,0,0.6); /* Gecko Browsers */
      }

      .uploadFinishedView_linkBoxCopied__vCF5D {
        color: #13BCB4;
        padding-left: unset;
        padding-right: 16px;
        text-align: right;
    }

    .uploadFinishedView_copyLinkButton__3LCZv {
        width: 39px;
        height: 34px;
        background: #13BCB4;
        border-radius: 5.89091px;
        cursor: pointer;
        border: none;
        position: relative;
        right: 4px;
        outline: none;
        -webkit-transition: -webkit-transform ease-in 0.1s, -webkit-box-shadow ease-in 0.25s;
        transition: -webkit-transform ease-in 0.1s, -webkit-box-shadow ease-in 0.25s;
        transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
        transition: transform ease-in 0.1s, box-shadow ease-in 0.25s, -webkit-transform ease-in 0.1s, -webkit-box-shadow ease-in 0.25s;
    }

    .uploadFinishedView_copyLinkButton__3LCZv:before, .uploadFinishedView_copyLinkButton__3LCZv:after {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        -webkit-transition: all ease-in-out 0.5s;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }

    .uploadFinishedView_copyLinkButton__3LCZv:before {
        display: none;
        top: -75%;
        background-image: -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, transparent 20%, #13BCB4 20%, transparent 30%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%);
        background-image: radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 20%, #13BCB4 20%, transparent 30%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%);
        background-size: 20% 20%, 20% 20%, 25% 25%, 30% 30%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
        -webkit-animation: uploadFinishedView_topBubbles__3CuZx ease-in-out 0.75s infinite;
                animation: uploadFinishedView_topBubbles__3CuZx ease-in-out 0.75s infinite;
      }
      
      .uploadFinishedView_copyLinkButton__3LCZv:after {
        display: none;
        top: -75%;
        background-image: -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%), -webkit-radial-gradient(circle, #13BCB4 20%, transparent 20%);
        background-image: radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
        -webkit-animation: uploadFinishedView_bottomBubbles__2Q820 ease-in-out 0.75s infinite;
                animation: uploadFinishedView_bottomBubbles__2Q820 ease-in-out 0.75s infinite;
      }

      .uploadFinishedView_copyLinkButtonAnimate__Ca5kk {
      }

      .uploadFinishedView_copyLinkButtonAnimate__Ca5kk:before, .uploadFinishedView_copyLinkButtonAnimate__Ca5kk:after {
        display: block;
      }

    .uploadFinishedView_linkBoxContent__y3huK {
        display: flex;
        margin-left: 22px;
    }

    .uploadFinishedView_divider__2Y1HU {
        border-top: 1.415px solid #E6E9EF;
        position: relative;
        top: 21px;
    }

      @-webkit-keyframes
      uploadFinishedView_topBubbles__3CuZx {  0% {
       background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
      }
       50% {
       background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
      }
       100% {
       background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
       background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
      }

      @keyframes
      uploadFinishedView_topBubbles__3CuZx {  0% {
       background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
      }
       50% {
       background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
      }
       100% {
       background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
       background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
      }

      @-webkit-keyframes
      uploadFinishedView_bottomBubbles__2Q820 {  0% {
       background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
      }
       50% {
       background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
      }
       100% {
       background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
       background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
      }

      @keyframes
      uploadFinishedView_bottomBubbles__2Q820 {  0% {
       background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
      }
       50% {
       background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
      }
       100% {
       background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
       background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
      }
.uploadExpiredView_container__Pynxl {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
   /* height: 100%;*/
    overflow-x: hidden; 
    text-align: center;
}

.uploadExpiredView_expiredIcon__3x51r {
width: 150px;
margin-left: 65px;
}

.uploadExpiredView_summaryTitle__dnOmL {
margin-top: 30px;
font-size: 14px;
line-height: 16px;
display: inline-block;
align-items: center;

color: #000000;
}
.noConnectionView_container__36J5u {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
   /* height: 100%;*/
    overflow-x: hidden; 
    text-align: center;
}

.noConnectionView_errorIcon__3dpqO {
 width: 200px;
 margin-left: 40px;
 height: 150px;
}

.noConnectionView_errorTitle__162FL {
    display: inline-block;
    margin-top: 60px;
    align-items: center;
    line-height: 16px;
    color: #74787B;
   }

   .noConnectionView_errorTitleSmall__3oi27 {
    font-size: 13px;
   }

.noConnectionView_errorContent__uJLJZ {
margin-top: 30px;
font-size: 14px;
line-height: 16px;
display: inline-block;
align-items: center;

color:#323232;
}
.transferStoppedView_container__3FPjD {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 100%;
    overflow-x: hidden; 
    text-align: center;
}

.transferStoppedView_clockIcon__3xFeW {
    max-width: 23%;
}

.transferStoppedView_text__1Ma09 {
font-weight: 600;
font-size: 90%;
line-height: 29px;
text-align: center;
letter-spacing: 0.568145px;
color: #13BCB4;
}
.transferWaitingView_container__1ZXg6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 100%;
    overflow-x: hidden; 
    text-align: center;
}

.transferWaitingView_clockIcon__3hBS5 {
    max-width: 23%;
}

.transferWaitingView_text__176Ls {
font-weight: 600;
font-size: 90%;
line-height: 29px;
text-align: center;
letter-spacing: 0.568145px;
color: #13BCB4;
}
.tab_selectedTab__170Jj {
color: #13BCB4;
font-size: 12px;
line-break: 23px;
line-height: 40px;
cursor: pointer;
border-bottom: 2px solid rgb(0 133 255 / 100%);
outline: none;
}

.tab_selectedTabForm__B_I-n {
  border-bottom: 4px solid rgb(19,188,180,1);
  font-weight: 500;
  }

.tab_tab__3ES-y { 
color: #13BCB4;
font-size: 12px;
line-break: 23px;
line-height: 40px;
cursor: pointer;
outline: none;
width: -moz-fit-content;
width: fit-content;
}

.tab_tab__3ES-y::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #13BCB4;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.tab_tab__3ES-y:hover::after {
  width: 100%;
}
.editTabsContainer_container__2qWNb {
  display: flex;
  border-bottom: 1px solid rgb(0, 0, 0, 0.15);
  margin-bottom: 10px;
}
.formContainer_container__1Hkn8 {
-webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 19px;
height: 400px;
max-width: 280px;
min-width: 280px;
background: white;
display: none;
}

@media screen and (min-width: 768px) {
  .formContainer_container__1Hkn8 {
    display: flex;
  }
}

.formContainer_containerExtended__3JrKf {
  height: 486px;
  }

.formContainer_innerContainer__2DA6W {
  height: 100%;
  width: -webkit-fill-available;
}

@media screen and (min-width: 768px) {
  .formContainer_innerContainer__2DA6W {
    z-index: 1000;
  }
}

.formContainer_formButton__1LeHB {
  background: #D7D7D7;
  border-radius: 37.2352px;
  width: 131px;
  height: 45px;
  border: none;
  outline: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  min-width: 131px;
}

.formContainer_formButton__1LeHB:hover{
  background: #0F9790;
}

  .formContainer_transferButtonContinaer__1R428 {
  /*  border-top: 1px solid #d4d7d9;*/
  /*  height: 4.5em;*/
    padding: 0 1.25em;
    position: relative;
    padding-top: 10px;
    min-height: 81px;
    display: flex;
    flex-direction: column;
    }
    
    .formContainer_transferButtonStyle__3mYUD {
    background: #13BCB4;
    margin-top: 12px;
    cursor: pointer;
    }

    .formContainer_verifyButtonContainer__2FwwS {
      bottom: 2vh;
      right: 4vh;
      }

    .formContainer_networkErrorButton__2aRAX {
      width: 179px;
    }

    .formContainer_downloadAllFilesStyle__2Jfrq {
      background: #13BCB4;
      width: 165px;
      height: 45px;
      cursor: pointer;
      bottom: 0px;
      top: 6px;
      }

    .formContainer_driveMoreButtonStyle__TW3aD {
    width: max-content;
    margin-top: 20px;
    background: #13BCB4;
    font-size: 16px;
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer;
    }

    .formContainer_addNewFilesButtonStyle__3kA13 {
      font-size: 16px;
      width: 179px;
      top: 5px;
      }

    .formContainer_downloadViewTitle__lyaT2 {
      display: inline-block;
      position: absolute;
      width: 230px;
      height: 22px;
      left: 74px;
      top: 200px;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      color: #000000;
    }

    .formContainer_downloadViewCallToAction__1x3Cz {
      position: relative;
      padding-top: 100px;
      font-size: 14px;
      line-height: 16px;
      left: 80px;
      text-decoration: underline;
      display: inline-block;
      align-items: center;
      color: #000000;
      }

      .formContainer_spinner__29KqH {
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }

      .formContainer_transferPanelContainer__2Wr8v {
       display: flex;
       flex-direction: column;
       /*padding-left: 10px;*/
       padding-top: 5px;
       grid-gap: 6px;
       gap: 6px;
      }

      .formContainer_cancelPanelContainer__1IuEm {
        display: block;
        padding-left: 10px;
        padding-top: 5px;
        position: relative;
        bottom: 8px;
       }

      .formContainer_transferPanelHeader__12pfQ {
        font-size: 15px;
        display: flex;
        align-items: center;
        color: #13BCB4;
        height: 10px;
        white-space: nowrap;
      }

      .formContainer_cancelTransfer__2lP7Y {
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.3px;
        -webkit-text-decoration-line: underline;
                text-decoration-line: underline; 
        color: #979797;
        cursor: pointer;
        margin-left: 20px;
        position: relative;
        bottom: 3px;
       /* position: relative;
        left: 30px;
        height: 10px;
        bottom: 5px;*/
      }

      .formContainer_cancelTransferEmail__1bEMm {
        left: 10px;
      }

      .formContainer_mbTransferred__3afIe {
        font-size: 12px;
        letter-spacing: 0.3px;
        color: #BBBDC0;
        white-space: nowrap;
      } 

      .formContainer_transferPanelHeaderWrapper__2ayRQ {
        display: flex;
      }

      .formContainer_transferPanelHeaderText__vrbch {
        display: flex;
        flex-direction: column;
        grid-gap: 3px;
        gap: 3px;
      }

      .formContainer_confirmCancel__nMmr_ {
        display: block;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.3px;      
        color: #979797;
        padding-bottom: 5px;
      }

      .formContainer_confirmCancelSelectionChips__1xFaO {
        display: flex;
        justify-content: center;
      }

      .formContainer_iconUploadFiles__jhcJi {
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 96px;
        margin-top: -60px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        z-index: 3000;
      }

      .formContainer_iconAddRecipients__ndV60 {
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 287px;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        z-index: 3000;
        margin-top: 127px;
      }

      .formContainer_iconClickSend__1Thye {
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 215px;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        z-index: 3000;
        margin-top: 335px;
      }

      .formContainer_iconCopyLink__3SR4f {
        -webkit-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 260px;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        z-index: 3000;
        margin-top: 222px;
      }
      
      .formContainer_arrow__3lsEU {
        position: absolute;
        top: 25px;
        width: 90%;
        height: 10px;
        background-color: #fff;
        -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
                box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
        -webkit-animation: formContainer_arrow__3lsEU 700ms linear infinite;
                animation: formContainer_arrow__3lsEU 700ms linear infinite;
        background: #13BCB4;
      }
      
      .formContainer_arrow__3lsEU::after, .formContainer_arrow__3lsEU::before {
        content: '';
        position: absolute;
        width: 60%;
        height: 10px;
        right: -8px;
        background-color:#13BCB4;
      }
      
      .formContainer_arrow__3lsEU::after {
        top: -12px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
      }
      
      .formContainer_arrow__3lsEU::before {
        top: 12px;
        -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
                box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
      }

      .formContainer_ldsRing__3ppA9 {
        width: 20px;
        margin-right: 13px;
        width: 20px;
        margin-right: 13px;
        position: relative;
        right: 9px;
        bottom: 6px;
        /*display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        top: 18px;
        position: relative;
        left: -9px;
        top: 2px;*/
      }
      .formContainer_ldsRing__3ppA9 div {
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 8px;
        border: 2px solid #fff;
        border-radius: 50%;
        -webkit-animation: formContainer_lds-ring__2G4G0 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                animation: formContainer_lds-ring__2G4G0 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #13BCB4 transparent transparent transparent;
      }
      .formContainer_ldsRing__3ppA9 div:nth-child(1) {
        -webkit-animation-delay: -0.45s;
                animation-delay: -0.45s;
      }
      .formContainer_ldsRing__3ppA9 div:nth-child(2) {
        -webkit-animation-delay: -0.3s;
                animation-delay: -0.3s;
      }
      .formContainer_ldsRing__3ppA9 div:nth-child(3) {
        -webkit-animation-delay: -0.15s;
                animation-delay: -0.15s;
      }

      @-webkit-keyframes formContainer_lds-ring__2G4G0 {
        0% {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
        }
      }

      @keyframes formContainer_lds-ring__2G4G0 {
        0% {
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
                  transform: rotate(360deg);
        }
      }

      @-webkit-keyframes formContainer_arrow__3lsEU {
        0% {
          left: 0;
        }
        50% {
          left: 10px;
        }
        100% {
          left: 0;
        }
      }

      @keyframes formContainer_arrow__3lsEU {
        0% {
          left: 0;
        }
        50% {
          left: 10px;
        }
        100% {
          left: 0;
        }
      }

.formContainerPreview_container__1u1mV {
flex-grow: 1;
-webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 19px;
height: 51vh;
max-width: 23vw;
max-width: 23vw;
background: white;
display: none;
}

@media screen and (min-width: 768px) {
  .formContainerPreview_container__1u1mV {
    margin-top: 0px;
    margin-left: 50px;
    display: flex;
  }
}

@media screen and (min-width: 769px) {
  .formContainerPreview_container__1u1mV {
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
  }
}

.formContainerPreview_innerContainer__2Rdpn {
  height: 100%;
  width: -webkit-fill-available;
}

@media screen and (min-width: 769px) {
  .formContainerPreview_innerContainer__2Rdpn {
    z-index: 1000;
  }
}

  .formContainerPreview_transferButtonContinaer__2ljqb {
    height: 4.5em;
    padding: 0 1.25em;
    position: relative;
    padding-top: 10px;
    }

    .formContainerPreview_downloadViewTitle__1usVt {
      display: inline-block;
      position: absolute;
      width: 230px;
      height: 22px;
      left: 74px;
      top: 200px;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      color: #000000;
    }

    .formContainerPreview_downloadViewCallToAction__1xzTo {
      position: relative;
      padding-top: 100px;
      font-size: 14px;
      line-height: 16px;
      left: 80px;
      text-decoration: underline;
      display: inline-block;
      align-items: center;
      color: #000000;
      }

      .formContainerPreview_spinner__2c0HR {
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }

.emailVerified_container__2Qlrr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.emailVerified_EmailVerifiedText__3Pcxh {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  line-height: 42px;
  letter-spacing: 0.3px;
  color: #323232;
  position: relative;
  bottom: 8px;
  margin-bottom: 10px;
}

.emailVerified_header__3uyOX {
  display: block;
  border-bottom: 1px solid #E6E9EF;
  padding-left: 42px;
  width: 100%;
}

.emailVerified_headerTitle__c0f27 {
  display: inline-flex;
}

.emailVerified_verifiedEmail__1hdZ1 {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #74787B;
  position: relative;
  bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

 .emailVerified_emailVerifiedContainer__3GRGV {
   display: block;
   margin-left: auto;
   margin-right: auto;
   position: relative;
   top: 8vh;
 }
.codeVerificationResend_container__1ZoUh {
  top: 0.1%;
  background-color: white;
  -webkit-animation: codeVerificationResend_verificationSlide__1g64W 0.5s linear 0s;
          animation: codeVerificationResend_verificationSlide__1g64W 0.5s linear 0s;
  display: block;
  position: relative;
  height: 100%;
  border-radius: 16px;
 }

 .codeVerificationResend_containerClosed__ZC0rF {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1%;
  background-color: white;
  display: none;
 }

.codeVerificationResend_verificationTitle__2BuZr {
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #323232;
  text-align: center;
  display: block;
}

.codeVerificationResend_verificationText__38Kam {
font-size: 15px;
line-height: 18px;
text-align: center;
letter-spacing: 0.3px;
color: #74787B;
display: block;
margin-top: 14px;
}

 .codeVerificationResend_sendEmailAgain__3Gyi0 {
  display: block;
  text-align: center;
  margin-top: 18px;
  color: #13BCB4;
  cursor: pointer;;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
 }

 .codeVerificationResend_emailSentImage__1Dbvg {
   display: block;
   margin-left: auto;
   margin-right: auto;
 }

 .codeVerificationResend_resendButtonDisabled__1GTK7 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: #D7D7D7;
  margin-top: 68px;
  width: 131px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 37.2352px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
 }

 .codeVerificationResend_resendButton__3EJEt {
  background: #13BCB4;
  cursor: pointer;
  position: relative;
  margin-top: 60px;
 }

 .codeVerificationResend_resendButton__3EJEt:hover{
  background: #0F9790;
 }

 .codeVerificationResend_resendButton__3EJEt:hover{
  background: #0F9790;
 }

 .codeVerificationResend_linkIcon__2uNFk {
  position: relative;
  top: 18px;
  left: 70px;
 }

 .codeVerificationResend_getLink__1UrOH {
  display: flex;
  position: relative;
  right: 5px;
}

.codeVerificationResend_getLinkText__UkQ0u {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
  text-align: center;
  color: #13BCB4;
  cursor: pointer;;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

 @-webkit-keyframes codeVerificationResend_slidein__-YPD7 {
  from { width: 0%; } to { width: 100%; }
}

 @keyframes codeVerificationResend_slidein__-YPD7 {
  from { width: 0%; } to { width: 100%; }
}

@-webkit-keyframes codeVerificationResend_verificationSlide__1g64W {
  from { margin-top: 20%; } to { margin-top: 0%; }
}

@keyframes codeVerificationResend_verificationSlide__1g64W {
  from { margin-top: 20%; } to { margin-top: 0%; }
}

.quotaUpgradeMessage_upgradeMessageContainer__3dbZm {
  min-width: 44.6vw;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  grid-area: upgradeMessage;
}

.quotaUpgradeMessage_limitReachedMessageContainer__2NrAl {
  background: #EA3989;
  grid-gap: unset;
  gap: unset;
  border-radius: 16px;
}

.quotaUpgradeMessage_limitReachedFlexWrapper__26LSz {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.quotaUpgradeMessage_limitReachedText__1QXdV {
font-weight: 500;
font-size: 16px;
line-height: 22px;
letter-spacing: 0.3px;
color: #FAFAFA;
margin-left: 0.77%;
margin-right: 0.4%;
}

.quotaUpgradeMessage_limitReachedUpgradeLink__3CdLc {
  margin: unset;
  padding: unset;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
}

.quotaUpgradeMessage_limitReachedResetDate__AOR8d {
  font-weight: 300;
  margin: unset;
}

.quotaUpgradeMessage_bellIcon__15gBv {
  
}

.quotaUpgradeMessage_transfersLeftContainer__37arO {
  display: flex;
  flex-direction: column;
  min-height: 58%;
  justify-content: space-evenly;
}

.quotaUpgradeMessage_textFlexWrapper__J2DQa {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
}

 .quotaUpgradeMessage_resetDate__aCyda {
 font-weight: 300;
 font-size: 12px;
 line-height: 21px;
 letter-spacing: 0.3px;
 color: #FFFFFF;
 opacity: 0.5;
 }

.quotaUpgradeMessage_upgradeMessageText__3hrM4 {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
}

.quotaUpgradeMessage_progressBar__Nw64q {
  background: #181C45;
  border-radius: 30px;
  height: 6px;
}

.quotaUpgradeMessage_progressFiller__490kF {
  background:  #13BCB4;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  max-width: 100%;
}

.quotaUpgradeMessage_progressFillerYellow__2t_KB {
  -webkit-transform: unset;
          transform: unset;
  background: #F5BE58;
  /*background: linear-gradient(90deg, #13BCB4 3.44%, #F5BE58 90.17);*/
 
}

.quotaUpgradeMessage_progressFillerRed__3qkY_ {
  /*background: linear-gradient(90deg, #EA3989 12.44%, #13BCB4 63.17%);*/
  background-color: #EA3989;
}

.quotaUpgradeMessage_upgradeButton__10U1u {
  background: inherit;
  border: 2.5px solid #EA3989;
  border-radius: 46.544px;
  height: 45px;
  min-width: 171px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #EA3989;
  outline: none;
  cursor: pointer;
  padding-left: 8%;
  padding-right: 8%;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .quotaUpgradeMessage_upgradeMessageContainer__3dbZm {
    min-width: 44.6vw;
    height: 62px;
    min-height: unset;
    top: 66px;
    -webkit-box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
    background: #252852;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 5%;
    gap: 5%;
    grid-area: upgradeMessage;
  }

  .quotaUpgradeMessage_limitReachedMessageContainer__2NrAl {
    background: #EA3989;
    grid-gap: unset;
    gap: unset;
    border-radius: unset;
  }

  .quotaUpgradeMessage_transfersLeftContainer__37arO {
    display: flex;
    flex-direction: column;
    min-height: 58%;
    justify-content: space-between;
    margin-left: 4%;
  }

  .quotaUpgradeMessage_upgradeButton__10U1u {
    padding: unset;
    background: #EA3989;
    border-radius: 37.2352px;
    height: 38px;
    min-width: 258px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    border: none;
    cursor: pointer;
    margin-right: 4%;
  }

  .quotaUpgradeMessage_upgradeMessageText__3hrM4 {
    font-size: 16px;
  }

  .quotaUpgradeMessage_resetDate__aCyda {
    font-size: 14px;
  }
}
.mainPage_homePageContainerEmbedded__CD8V7 {
  display: grid;
  grid-template-columns: 15% 1fr 15%;
  grid-template-rows:10.5%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  grid-template-areas:
      "header header header"
      ". homePageWrapper ."
      ". upgradeMessage ."
      ". footer ."
      ;
  }

  .mainPage_homePageContainer__Jrhhp {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 6.7% 4% 1fr;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header"
        "."
        "homePageWrapper"
        "upgradeMessage"
        "footer";
  }

  .mainPage_pageTitle__2s5Yy {
    grid-area: pageTitle;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    display: none;
  }

  .mainPage_homePageWrapper__BHsYL {
    display: flex;
    grid-area: homePageWrapper;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .mainPage_centerContent__7Za5s {
    grid-area: centerContent;
    display: flex;
    flex-direction: column;
    grid-gap: 4vh;
    gap: 4vh;
    min-width: 54vw;
  }

  .mainPage_formWrapper__12Jci {
    grid-area: formWrapper;
  }

  .mainPage_mainContentOuterWrapper__3Nnsf {
    display: block;
  }


  .mainPage_poweredByText__1d8lq {
    font-size: 15px;
    letter-spacing: 0.444977px;
    color:white;
    display: block;
    outline: none;
    border: none;
  }

  .mainPage_poweredByLink__3kRcl {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #13BCB4;
  }

  .mainPage_poweredByContainer__2wQMR{
    display: block;
    display: block;
    margin-top: 3vh;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .mainPage_content__3yFYH {
    display: flex;
    width: 100%;
    flex-wrap: wrap-reverse;
    align-items: center;
  }

  .mainPage_itemInvisible__1yHxa {
    display: none;
  }

  .mainPage_minShrinkInvisibleDiv__1hbS5 {
    display: none;
  }

  .mainPage_invisiblePlaceholder__3IWTZ {
    height: 62px;
  }

  .mainPage_defaultDownloadContainer__3IW68 { 
  }

  .mainPage_embeddedMinimizedContainer__2NvMs {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:1fr;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header"
        "homePageWrapper"
        "footer"
        ;
  }

  .mainPage_embeddedWithoutUpgradeContainer__1cSXq {
    display: grid;
    grid-template-columns: 12% 1fr 12%;
    grid-template-rows: 10.5%;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header header header"
        ". homePageWrapper .";
  }


  @media screen and (min-width: 768px) {
    .mainPage_homePageContainer__Jrhhp {
      display: grid;
      grid-template-columns: 3vw 1fr;
      grid-template-rows: minmax(60px,8%) minmax(62px,8.8vh) 7% 4% 1fr 7%;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      grid-template-areas:
          "header header"
          "upgradeMessage upgradeMessage "
          ". ."
          "pageTitle pageTitle"
          ". homePageWrapper"
          "footer footer";
      ;
    }

    .mainPage_tutorialContainer__c9Wbd {
      display: grid;
      grid-template-columns: 3% 1fr;
      grid-template-rows:minmax(60px,8%) 17% 1fr 14% 7%;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      grid-template-areas:
      "header header"
      ". ."
      ". homePageWrapper"
      ". ."
      "footer footer";
    }

    .mainPage_celebrationContainer__1uJHO {
      grid-template-columns: 3vw 1fr 30.5vw;
      grid-template-areas:
      "header header header"
      ". . ."
      "pageTitle pageTitle pageTitle"
      ". homePageWrapper ."
      "footer footer footer";
    }

  .mainPage_homePageContainerPaid__3bN7x {
    display: grid;
    grid-template-columns: 3vw 1fr;
    grid-template-rows: minmax(60px,8%) 10% 4% 1fr 7%;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header header"
        ". ."
        "pageTitle pageTitle"
        ". homePageWrapper"
        "footer footer";
    ;
  }

  .mainPage_homePageWrapper__BHsYL {
    flex-direction: unset;
    justify-content: unset;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: unset;
    grid-gap: 5vw;
    gap: 5vw;
  }

  .mainPage_tutorialPageWrapper__1CHRm {
    grid-gap: 8%;
    gap: 8%;
  }

  .mainPage_celebrationPageWrapper__2TJhQ {
    align-items: center;
    grid-gap: 6vw;
    gap: 6vw;
  }

  .mainPage_brandPreviewWrapper__WrdZv {
  }

  .mainPage_defaultDownloadContainer__3IW68 {
    grid-template-columns: 3vw 1fr 15%;
    grid-template-rows: minmax(60px,8%) 3.8% 1fr 7%;
    grid-template-areas:
        "header header header"
        ". . ."
        ". homePageWrapper ."
        "footer footer footer";
  }

  .mainPage_defaultDownloadPageWrapper__3AGVi {
    align-items: center;
    grid-gap: unset;
    gap: unset;
  }

  .mainPage_defaultDownloadPageCenterContent__1DAEC {
    flex-grow: 1;
  }

  .mainPage_poweredByLink__3kRcl {
    text-align: unset;
    }

    .mainPage_poweredByContainer__2wQMR {
      width: unset;
      margin-right: unset;
    }

    .mainPage_content__3yFYH {
      flex-wrap: unset;
      justify-content: space-between;
      align-items: unset;
      height: 100%;
    }

    .mainPage_itemInvisible__1yHxa {
      display: unset;
      flex: 0 1 auto;
      width: 520px;
      height: 100px;
      visibility: hidden;
    }

    .mainPage_minShrinkInvisibleDiv__1hbS5 {
      display: unset;
      flex: 1 0 40px;
  }

  .mainPage_pageTitle__2s5Yy {
    display: unset;
  }

  }

.customizationCTA_container__ThQDi {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.customizationCTA_linkNote__bqq_F {
  display: none !important;
  font-size: 10px;
  line-height: 18px;
  color: #979797;
  text-align: center;
  display: block;
  margin-top: 15px;
}

.customizationCTA_ctaContent__2V0xh {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 44px;
}

.customizationCTA_ctaTitle__2MjkF {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.244445px;
  color: #323232;
}

.customizationCTA_ctaText__3W5wY {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.customizationCTA_ctaLink__3zm1U {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 195px;
  height: 45px;
  background: #13BCB4;
  border-radius: 37.2352px;
  margin-top: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;

}

.customizationCTA_ctaLink__3zm1U:hover {
  background: #0F9790;
}

.customizationCTA_ctaLinkText__1X_rG {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  padding-left: 10px;
  color: #FFFFFF;
}

.customizationCTA_linkWrapper__23chC {
  display: block;
  padding-top: 34px;
}
.upsellCTA_container__2-Pc1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upsellCTA_linkNote__j8ABq {
  display: none !important;
  font-size: 10px;
  line-height: 18px;
  color: #979797;
  text-align: center;
  display: block;
  margin-top: 15px;
}

.upsellCTA_ctaContent__3kWZg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upsellCTA_ctaTitle__3hG5u {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.244445px;
  color: #323232;
}

.upsellCTA_ctaText__2LI9F {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.upsellCTA_ctaLink__3U37n {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 195px;
  height: 45px;
  background: #13BCB4;
  border-radius: 37.2352px;
  margin-top: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;

}

.upsellCTA_ctaLink__3U37n:hover {
  background: #0F9790;
}

.upsellCTA_ctaLinkText__2_g2Z {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  padding-left: 10px;
  color: #FFFFFF;
}

.upsellCTA_linkWrapper__hTA6b {
  display: block;
  padding-top: 34px;
}

.upsellCTA_textWrapper__2Iyfh {
  margin-top: 3.1vh;
}
.customViewContent_gridContainer__KkcGc {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 0% 1fr 7%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  grid-template-areas:
      "header"
      "homePageContainer"
      "footer";
  ;
}

.customViewContent_homePageContainer__3zWQf {
  width: 100%;
  grid-area: homePageContainer;
}

.customViewContent_homePageContainerFlex__deFk- {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  grid-area: homePageContainer;
}

.customViewContent_formWrapper__14EnX {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.customViewContent_formWrapperFlex__kp558 {

}

.customViewContent_mainContentOuterWrapper__YhF7N {
  display: block;
  z-index: 2000;
}


.customViewContent_poweredByText__2E9gq {
  font-size: 15px;
  letter-spacing: 0.444977px;
  color:white;
  display: block;
  outline: none;
  border: none;
}

.customViewContent_poweredByLink__3n5Sv {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  padding: 0;
  margin: 0;
}

.customViewContent_poweredByContainer__2697G{
  display: block;
  display: block;
  margin-left: 50px;
  margin-top: 3vh;
}

@media screen and (min-width: 768px) {
  .customViewContent_homePageContainer__3zWQf {
    flex-flow: row nowrap; /* required */
  }
  .customViewContent_formWrapper__14EnX {
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    flex: none;
}

.customViewContent_formWrapperFlex__kp558 {
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 9vh;
  left: 50px;
}
  .customViewContent_mainContentOuterWrapper__YhF7N {
    z-index: 100;
  }
  .customViewContent_homePageContainerFlex__deFk- {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: unset;
  }
}
.animatedArrow_arrowContainer___CL9b {
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  width: 80px;
  height: 60px;
  display: block;
  position: absolute;
  left: 950px;
  top: 630px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  z-index: 1000;
}

.animatedArrow_arrow__3DHZc {
  position: absolute;
  top: 25px;
  width: 90%;
  height: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
          box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
  -webkit-animation: animatedArrow_arrow__3DHZc 700ms linear infinite;
          animation: animatedArrow_arrow__3DHZc 700ms linear infinite;
  background: #13BCB4;
  z-index: 3000;
}

.animatedArrow_arrow__3DHZc::after, .animatedArrow_arrow__3DHZc::before {
  content: '';
  position: absolute;
  width: 60%;
  height: 10px;
  right: -8px;
  background-color:#13BCB4;
}

.animatedArrow_arrow__3DHZc::after {
  top: -12px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.animatedArrow_arrow__3DHZc::before {
  top: 12px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
          box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@-webkit-keyframes animatedArrow_arrow__3DHZc {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

@keyframes animatedArrow_arrow__3DHZc {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}
.addBrandCta_addBrandCtaWrapper__B2ieI {
  display: block;
}

.addBrandCta_addBrandCtaContent__dRd5b {
  display: flex;
  align-items: flex-end;
  min-width: 800px;
  margin-top: 4%;
}

.addBrandCta_addBrandCtaContentWrapper__2A9nP {
  display: block;
}

.addBrandCta_addBrandCtaRight__3sSjk {
  display: block;
  margin-left: 40px;
  width: 327px;
  text-align: left;
  position: relative;
  bottom: 10px;
}

.addBrandCta_brandPreviewImage__3DXcO {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 431px;
  max-height: 326px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.addBrandCta_addBrandCtaText__bZM_d {
  width: max-content;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  max-width: 297px;
  position: relative;
  top: 10px;
}

.addBrandCta_addBrandCtaTextBold__3yLKR {
  width: max-content;
  display: block;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 40px;
}

.addBrandCta_buttonContainer__licvc {
  display: block;
}

.addBrandCta_addBrandButton__2SKyg {
  min-width: 208px;
  width: -moz-fit-content;
  width: fit-content;
  height: 43px;
  background: #13BCB4;
  border-radius: 49.3593px;
  display: block;
  border: none;
  outline: none;
  color:  white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  margin-top: 28px;
  cursor: pointer;
  min-width: 144px;
  text-align: center;
  display: block;
  padding-top: 5px;
  margin-left: 0;
  position: relative;
  top: 10px;
}

.addBrandCta_skipBrand__3Rwya {
  margin-top: 18px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #13BCB4;
  display: block;
  cursor: pointer;
  position: relative;
  left: 57px;
}

.addBrandCta_extensionIcon__2K-g- {

}

.addBrandCta_buttonTextWrapper__1sRp_ {
  display: flex;
  align-items: center;
}

.addBrandCta_buttonText__XwAR0 {
font-weight: 600;
font-size: 21.1204px;
line-height: 32px;
align-items: center;
color: #FFFFFF;
margin-left: 8px;
}
.signupModal_overlayContainer__1vjcl {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.signupModal_container__2ZdCE {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 840px;
  min-height: calc(min(95%,620px));
  /*height: 78vh;*/
  max-height: 629px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
  display: flex;
  overflow: hidden;
}

.signupModal_leftBlock__1G4Ou {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.signupModal_rightBlock__298Gl {
  display: flex;
  flex-direction: column;
  width: 41%;
  background: #E7F8F7;
  align-items: center;
}

.signupModal_modalContent__3jJDu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
}

.signupModal_iconStyle__z1lpB {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signupModal_title__2wsj9 {
font-weight: 600;
font-size: 30px;
line-height: 45px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
display: block;
}

.signupModal_subTitle__2e68m {
font-size: 14px;
line-height: 27px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
margin-top: 17px;
}

.signupModal_featuresContainer__1chER {
  display: block;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 33px;
}

.signupModal_featuresTitle__3aIU4 {
font-size: 24px;
line-height: 36px;
text-align: center;
letter-spacing: 0.3px;
color: #323232;
display: block;
width: max-content;
}

.signupModal_featureList__M2isb {
width: 100%;
height: 43%;
background: #EEF6FD;
border-radius: 0px;
margin-top: 20px;
display: block;
}

.signupModal_fetaureContainer__1BrHr {
  margin-top: 8px;
}

.signupModal_featureText__2rs0s {
font-size: 13px;
line-height: 35px;
letter-spacing: 0.589982px;
color: #74787B;
}

.signupModal_footer__gcTWO {
  margin-top: auto;
  display: block;
  position: absolute;
  bottom: 40px;
}

.signupModal_alreadyHaveAccount__14faa {
font-size: 16px;
line-height: 18px;
text-align: center;
letter-spacing: 0.3px;
color: #74787B;
display: flex;
position: relative;
left: 24px;
}

.signupModal_confirmationContainer__rFwNn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.signupModal_confirmationText__2KEld {
font-size: 16.8736px;
line-height: 25px;
text-align: center;
letter-spacing: 0.42184px;
color: #979797;
text-align: center;
display: block;
}

.signupModal_buttonsContainer__3Ixf8 {
  display: flex;
  justify-content: space-evenly;
}

.signupModal_button__1g6kl {
  border-radius: 51.7581px;
  width: 41%;
  height: 7%;
  min-height: 52px;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  align-items: center;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.signupModal_upgradePlan__1U9j4 {
  background: #13BCB4;
  color: white;
  border: none;
}

.signupModal_downgradePlan__u_Aeo {
  background: white;
  border: 2px solid #13BCB4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #13BCB4;
}

.signupModal_loaderText__3oSIV {
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #FFFFFF;
  display: block;
  position: relative;
  top: 14px;
}

.signupModal_loaderTextSubHeadline__3IcrU {
  font-size: 12px;
}

.signupModal_whySignupWrapper__3rxcx {
  display: block;
}

.signupModal_whySignupContainer__3OHf0 {
  margin-bottom: 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 329px;
  height: 97px;
  background: #E7F8F7;
  border-radius: 16px;
}

.signupModal_whySignupContainerMinimized__XgwnP {
  background: white;
  margin-bottom: 12px;
  position: relative;
  right: 22px;
  height: 57px;
}

.signupModal_whySignupTextContainer__2p31a {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.signupModal_whySignup__3Onl8 {
font-size: 14px;
line-height: 17px;
letter-spacing: 0.275331px;
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;
color: #13BCB4;
position: relative;
left: 7px;
}

.signupModal_whySignupTooltipContainer__2Bhz_ {
  /*display: block;
  position: relative;
  bottom: 30px;
  left: 77px;*/
  display: block;
  position: relative;
  left: 33px;
  z-index: 100;
}

.signupModal_whySignupTooltip__32C7x {
/*width: 240px;
height: 81px;*/
width: 256px;
height: 84px;
background: #FFFFFF;
-webkit-box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
border-radius: 16px;
}

.signupModal_whySignupTooltipPointer__2mOvI {
  width: 0;
  height: 0;
  background: transparent;
  position: relative;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ffff;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 18px;
}

.signupModal_whySignupTooltipText__3Yux3 {
  font-size: 10px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.348485px;
  color: #74787B;
  display: block;
  position: relative;
  top: 10px;
  }

.signupModal_whySignupText__U5hQi {
font-size: 13px;
line-height: 21px;
text-align: center;
letter-spacing: 0.348485px;
color: #74787B;
margin-top: 6px;
}

.signupModal_whySignupTextHidden__3lfjK {
  display: none;
}

.signupModal_formGif__TIk_O {
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-radius: 16px;
  width: 79%;
  margin-top: 86px;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .signupModal_overlayContainer__1vjcl {
    background-color: rgba(0, 0, 0, 0);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    filter: drop-shadow(0px 71px 71px rgba(0, 0, 0, 0.6400691));
  }
}

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .signupModal_overlayContainer__1vjcl {
      background-color: rgba(0, 0, 0, 0.85);
    }
  }

.productTutorial_productTutorialContainer__8Cli1 {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.productTutorial_tutorialStepContainer__SZT47 {
  display: block;
  margin-left: 49.5px;
  width: max-content;
}

.productTutorial_tutorialStep__1Vjs1{
  align-items: center;
  justify-content: center;
  display: flex;
  width: 13vw;
  max-width: 253.37px;
  height: 192.09px;
  background: #252852;
  border-radius: 18.8555px;
  min-width: 187px;
}

.productTutorial_currentTutorialStep__1zLmm {
  border: 1.17847px solid #13BCB4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.productTutorial_stepImage__1iu8M {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  height: 100%;
}

.productTutorial_stepImageUploadFiles__2TQ8s {
  width: 80%;
  background: white;
  border-radius: 11px;
  margin-left: auto;
  margin-right: auto;
  max-width: 140px;
  display: flex;
  justify-content: center;
}

.productTutorial_stepImageUploadFilesLink__QZn1x {
  display: flex;
  justify-content: center;
  width: 80%;
  background:white;
  border-radius: 11px;
  height: 90%;
  max-width: 140px;
}

.productTutorial_stepImageAddRecipients__1O_3s {
  display: flex;
  justify-content: center;
  background:white;
  width: 80%;
  height: 90%;
  border-radius: 11px;
  max-width: 140px;
}

.productTutorial_stepImageClickSend__1TYDY {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 11px;
  max-width: 140px;
}

.productTutorial_stepTextContainer__3YAil {
  display: flex;
  margin-top: 17px;
}

.productTutorial_stepText__2E8E0 {
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.491431px;
  color: #FFFFFF;
  margin-left: 20px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  .productTutorial_stepText__2E8E0 {
    font-size: 16px;
}
}

.productTutorial_completedStep__g_s8w {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42.2px;
  height: 42.2px;
  border-radius: 50%;
  border: 2.31237px solid #13BCB4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #13BCB4;
}

.productTutorial_checkMark__EUzEw {
  border-width: 3px;
  height: 18px;
  width: 11px;
  border-color: #181C45;
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-bottom: 3px solid #181C45;
  border-right: 3px solid #181C45;
  position: relative;
  bottom: 3px;
}
.addExtensionCta_addBrandCtaWrapper__2wOKo {
  display: block;
}

.addExtensionCta_addBrandCtaContent__WO4zl {
  display: flex;
  align-items: flex-end;
  min-width: 800px;
  margin-top: 4%;
}

.addExtensionCta_addBrandCtaContentWrapper__3WnQX {
  display: block;
}

.addExtensionCta_addBrandCtaRight__2o0Q6 {
  display: block;
  margin-left: 40px;
  width: 327px;
  text-align: left;
  position: relative;
  bottom: 10px;
}

.addExtensionCta_brandPreviewImage__Om6bQ {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 431px;
  max-height: 326px;
  cursor: pointer;
}

.addExtensionCta_addBrandCtaText__9VA3_ {
  width: max-content;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  max-width: 297px;
  position: relative;
  top: 10px;
}

.addExtensionCta_addBrandCtaTextBold__2T039 {
  width: max-content;
  display: block;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 40px;
}

.addExtensionCta_buttonContainer__1F05Y {
  display: block;
}

.addExtensionCta_addBrandButton__O8IkF {
  min-width: 208px;
  width: -moz-fit-content;
  width: fit-content;
  height: 43px;
  background: #13BCB4;
  border-radius: 49.3593px;
  display: block;
  border: none;
  outline: none;
  color:  white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  margin-top: 28px;
  cursor: pointer;
  min-width: 144px;
  text-align: center;
  display: block;
  padding-top: 5px;
  margin-left: 0;
  position: relative;
  top: 10px;
}

.addExtensionCta_skipBrand__VfJPq {
  margin-top: 18px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #13BCB4;
  display: block;
  cursor: pointer;
  position: relative;
  left: 57px;
}

.addExtensionCta_extensionIcon__1w62l {

}

.addExtensionCta_buttonTextWrapper__2HQv4 {
  display: flex;
  align-items: center;
}

.addExtensionCta_buttonText__3Gh2L {
font-weight: 600;
font-size: 21.1204px;
line-height: 32px;
align-items: center;
color: #FFFFFF;
margin-left: 8px;
}
.celebrationScreen_completionScreenRoot__2sdVC {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.celebrationScreen_completionScreenContainer__JGDpI {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.celebrationScreen_completionScreenTitle__ORojy {
font-weight: bold;
font-size: 56px;
line-height: 74px;
text-align: center;
letter-spacing: -0.244445px;
color: #FFFFFF;
}

.celebrationScreen_completionScreenDescription__1aCqL {
margin-top: 39px;
font-size: 28px;
line-height: 40px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.3px;
color: #FFFFFF;
}

.celebrationScreen_button__3omyf {
width: 225.47px;
height: 50px;
background: #13BCB4;
border-radius: 41.2172px;
margin-top: 20px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
outline: none;
border: none;
}

.celebrationScreen_buttonContent__3dtf7 {
display: flex;
align-items: center;
justify-content: center;
font-weight: 600;
font-size: 19.9249px;
line-height: 30px;
display: flex;
align-items: center;
color: #FFFFFF;
}
.mainContent_container__3kJka {
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
  justify-items: center;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .mainContent_container__3kJka {
    flex-wrap: nowrap;
  }
}

.mainContent_homePageContainer__1Bxad {
  display: flex;
  width: 100%;
  flex-wrap: wrap-reverse;
}

@media screen and (min-width: 768px) {
  .mainContent_homePageContainer__1Bxad {
    flex-flow: row nowrap; /* required */
  }
}

.mainContent_title__2MRFn {
  font-weight: 900;
  justify-content: center;
  flex-grow: 3;
  font-size: 16px;
  line-height: 54px;  
  text-align: center;
  letter-spacing: 12px;
  text-transform: uppercase;
  color: #FFFFFF;
  }

  @media screen and (min-width: 321px) {
    .mainContent_title__2MRFn {
      font-size: 20px;
    }
  }

  @media screen and (min-width: 768px) {
    .mainContent_title__2MRFn {
      font-size: 34px;
    }
  }

  .mainContent_desc__57hV1 {   
    flex-grow: 1;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
   /* line-height: 74px;*/
    text-align: center;
    letter-spacing: -0.244445px;
    color: #FFFFFF;


  }

  @media screen and (min-width: 768px) {
    .mainContent_desc__57hV1 {
      font-size: 46px;
    }
  }

  .mainContent_img__a4LZI {
    position: relative;
    flex-grow: 1;
    max-width: 351px;
    /*margin-right: 100px;*/
    max-height: 450px;
    display: none;
  }

  @media screen and (min-width: 768px) {
    .mainContent_img__a4LZI {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .mainContent_oneLiner__1Nh7T {
    margin: 0;
    flex-grow: 1;
    margin-bottom: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 40px;
    display: block;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  @media screen and (min-width: 768px) {
    .mainContent_oneLiner__1Nh7T {
      font-size: 18px;
    }
  }

  .mainContent_mobileButton__2K4JR {
    display: none;
    padding: 9px 15px;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 2px;
    background-color: #fff;
    font-family: Ubuntu, Helvetica, sans-serif;
    color: #000;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 20px;
  }
  
  .mainContent_mobileButton__2K4JR {
    display: inline-block;
  }

  @media screen and (min-width: 768px) {
    .mainContent_mobileButton__2K4JR {
      display: none;
    }
  }

  .mainContent_header__2cBxM {
    display: flex;
    justify-items: flex-start;
    width: 986px;
    background-color: #FAFAFA;
    height: 30px;
    border-radius: 4px;
  }

  .mainContent_poweredByWrapper__23l0e {
    display: block;
    margin-top: 10px;
  }

  .mainContent_poweredByText___Meou {
    margin-top: 20px;
    display: block;
    outline: none;
    border: none;
    border-radius: 29px;
    background-color: transparent;
    font-size: 14px;
    color:white;
    text-align: center;
  }

  .mainContent_shareFilesButton__1Qce4 {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    text-decoration: underline;
    cursor: pointer;
    display: block;
    outline: none;
    border: none;
    border-radius: 29px;
    background-color: transparent;
    font-size: 14px;
    color:white;
    text-align: center;
  }

  .mainContent_linkNote__21DGB {
    font-size: 12.5px;
    line-height: 24px;
    display: -webkit-flex;
    display: block;
    align-items: center;
    text-align: center;
    color: #013348;
    margin: 8px 0px;
    margin-top: 25px;
  }

  .mainContent_mainContentInnerWrapper__39OHA {
    display: block;
    flex-grow: 3;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .mainContent_mainContentInnerWrapper__39OHA {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      width: 100%;
      flex: none;
      margin-left: -100%; /* required */
  }
  }

  .mainContent_contentWrapper__1Cb_4 {
    text-align: center;
    margin-top: 10vh;
  }

  @media screen and (min-width: 768px) {
    .mainContent_contentWrapper__1Cb_4 {
      margin-top: 50px;
  }
  }

  .mainContent_mainContentOuterWrapper__2RQy8 {
    display: block;
  }

  @media screen and (min-width: 768px) {
    .mainContent_mainContentOuterWrapper__2RQy8 {
      
  }
  }

  .mainContent_formWrapper__2H3SO {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 768px) {
    .mainContent_formWrapper__2H3SO {
      display: flex;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      width: 100%;
      flex: none;
  }
  }

  .mainContent_publishedTitle__1FGKG {
    display: block;
    text-align: center;
    margin-top: 120px;
    font-size: 56px;
    line-height: 74px;
    letter-spacing: -0.244445px;
    color: #323232;
  }

  .mainContent_startCustomizing__19JXs {
    background: #13BCB4;
    cursor: pointer;
    border-radius: 37.2352px;
    width: 231px;
    height: 65px;
    border: none;
    outline: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
  }
  
  .mainContent_startCustomizing__19JXs:hover{
    background: #0F9790;
  }

  .mainContent_upsellCtaTitle__3qJBH {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.244445px;
    color: #323232;
  }

  .mainContent_transferLimitExceededTitle__2v4YR {
    color: #EA3989;

  }
  
  .mainContent_upsellCtaText__1ZN1g {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }

  .mainContent_validationTooltipContent__fHiz5 {
    display: flex;
    justify-content: center;
  }

  .mainContent_tooltipValidationIcon__5OgjS {
    margin-right: 16px;
  }

  .mainContent_tooltipValidationText__1WzA_ {
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.422139px;
    color: #74787B;
  }

  .mainContent_bellIcon__35teI {

  }
.footer_container__1vXye {
    width: 100vw;
    z-index: 1000;
    color: #fff;
    opacity: 0.5;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    display: flex;
    align-items: center;
    grid-area: footer;
    justify-content: center;
}

@media screen and (min-width: 769px) {
  .footer_container__1vXye {
    justify-content: unset;
    /* position: absolute;
    text-align: unset;
    position: unset;
   padding-bottom: 5vh;
    max-width: 15vw;*/
  }
}

.footer_footerLink__xtcP6 {
  color: #13BCB4;
 /* color: #fff;
  opacity: 0.5;*/
  margin-left: 0;
  margin-right: auto;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  display: inline-block;
  padding: 0;
  margin-right: 5vw;
}

@media screen and (min-width: 769px) {
  .footer_footerLink__xtcP6 {
    margin-right: 2vw;
  }
}

.footer_innerContainer__1_qw2 {
  display: inline-block;
  /*padding-left: 43px;
  width: inherit;*/
}

@media screen and (min-width: 769px) {
  .footer_innerContainer__1_qw2 {
    width: unset;;
    padding-left: 43px;
  }
}

@media screen and (min-width: 769px) {
  .footer_footerLink__xtcP6 {
    margin-right: 2vw;
  }
}
.mobileHeader_nav__1q1jI {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    font-weight: bold;
    text-align: center;
    width: auto;
    height: 6.7vh;
    /*padding-left: 10px;*/
}

.mobileHeader_logo__2kdyw {
  position: relative;
  bottom: 12px;
  display: inline-block;
  width: 0vh;
  height: 0vh;
  padding-top: 45px;
  margin-left: 57px;
  border: none;
  outline: none;
  z-index: 1;
  text-decoration: none !important;
  font-weight: bold;
  font-size: 28px;
  line-height: 74px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #13BCB4;
}

.mobileHeader_linkStyle__3HFpC {
  padding: 2%;
  margin: 0;
}

.mobileHeader_linkStyleExpanded__1rN_x {
  /*position: relative;
  left: 20px;*/
}

.mobileHeader_headerContainer__11YLn {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  width: auto;
  max-width: 290px;
  display: flex;
  align-items: center;
}

.mobileHeader_expandContainer__3MBt8 {
  display: block;
}

.mobileHeader_expand__24i4I {
  border-width: 3px;
  height: 13px;
  width: 13px;
  border-color: #13BCB4;
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-bottom: 1.5px solid #13BCB4;
  border-right: 1.5px solid #13BCB4;
  position: relative;
  bottom: 5px;
  left: 23px;
  cursor: pointer;
}

.mobileHeader_minimize__2-wLQ {
  border-width: 3px;
  height: 13px;
  width: 13px;
  border-color: #13BCB4;
  display: inline-block;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  border-bottom: 1.5px solid #13BCB4;
  border-right: 1.5px solid #13BCB4;
  position: relative;
  top: 4px;
  left: 65px;
  cursor: pointer;
}

.mobileHeader_infoTooltipContainerWrapper__14Y6r {
  display: block;
  /*position: relative;
  bottom: 6px;
  top: 22px;*/
  position: absolute;
  
}

.mobileHeader_infoTooltipContainerWrapperMinimize__mk720 {
  margin-left: 11.5px;
  top: 23px;
}

.mobileHeader_infoTooltipContainerWrapperExpand__A0Tdf{
  margin-left: -32.5px;
}

.mobileHeader_infoTooltipContainer__H3Sak {
width: 119.81px;
height: 47.98px;
background: #32366D;
-webkit-box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
border-radius: 16px;
display: flex;
justify-content: center;
align-items: center;
}

.mobileHeader_infoTooltipText__20tMH {
font-size: 14px;
line-height: 21px;
text-align: center;
letter-spacing: 0.3px;
color: #FFFFFF;
}

.mobileHeader_infoTooltipPointer__Souy5 {
  width: 0;
  height: 0;
  background: transparent;
  position: relative;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #32366D;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg)
}

.mobileHeader_closeIcon__RJWWT {
  cursor: pointer;
}

.mobileHeader_closeIconExpanded__SfNoc {
  position: relative;
  left: 85px;
}

.mobileHeader_closeIconMinimized__10J5m {
  position: relative;
  left: 39px;
}

.mobileHeader_dragIconExpanded__2cbPo {
  cursor: -webkit-grab;
  cursor: grab;
  position: relative;
  right: 255px;
  top: 2px;
}

.mobileHeader_dragIconMinimized__3yz7Z {
  cursor: -webkit-grab;
  cursor: grab;
  position: relative;
  right: 215px;
  top: 2px;
}




.footerDemo_container__3ly30 {
    width: 100%;
    height: 126px;
    z-index: 1000;
    display: flex;
    position: absolute;
    bottom: 0;
    /*margin-top: auto;*/
    position: absolute;
    bottom: 0px;
    background: #FFFFFF;
    border: 2.08267px solid #EBEBEB;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
    border-radius: 21.7455px 21.7455px 0px 0px;
}

@media screen and (min-width: 769px) {
  .footerDemo_container__3ly30 {
     /*margin-top: auto;
   padding-bottom: 5vh;
    max-width: 15vw;*/
  }
}

.footerDemo_footerLink__3JJDb {
  color: #13BCB4;
 /* color: #fff;
  opacity: 0.5;*/
  margin-left: 0;
  margin-right: auto;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  display: inline-block;
  padding: 0;
  margin-right: 5vw;
}

@media screen and (min-width: 769px) {
  .footerDemo_footerLink__3JJDb {
    margin-right: 2vw;
  }
}

.footerDemo_innerContainer__vMTK- {
  display: flex;
  padding-left: 5vw;
  position: relative;
  top: 25px;
  height: 0;
}

@media screen and (min-width: 769px) {
  .footerDemo_footerLink__3JJDb {
    margin-right: 2vw;
  }
}

.footerDemo_ctaHeaderContainer__1ahqe {
  display: block;
  position: relative;
  top: 15px;
  height: 0;
}

.footerDemo_ctaHeader__3tcid {
font-weight: bold;
font-size: 34px;
letter-spacing: -0.244445px;
color: #323232;
}

.footerDemo_ctaSubHeader__2NOpg {
font-size: 16px;
line-height: 30px;
display: flex;
align-items: center;
letter-spacing: 0.3px;
color: #323232;
}

.footerDemo_ctaLink__5QIj0 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 175px;
  height: 45px;
  background: #13BCB4;
  border-radius: 37.2352px;
  margin-top: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: 18px;
  left: 71px;
}

.footerDemo_ctaLink__5QIj0:hover {
  background: #0F9790;
}

.footerDemo_ctaLinkText__Bh4ON {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  padding-left: 10px;
  color: #FFFFFF;
}
.trialExpiredModal_overlayContainer__1uNuG {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
  z-index: 2000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.trialExpiredModal_container__30CyC {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 28.8vw;
  min-width: 414px;
  max-width: 430px;
  min-height: 620px;
  /*height: 78vh;*/
  max-height: 820px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
}

.trialExpiredModal_modalContent__2bJMb {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.trialExpiredModal_iconStyle__3j_ra {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.trialExpiredModal_title__kfYRv {
font-size: 30.5806px;
line-height: 46px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
margin-top: 25px;
display: block;
}

.trialExpiredModal_subTitle__ARTTq {
font-weight: 600;
font-size: 19.4604px;
line-height: 25px;
text-align: center;
letter-spacing: 0.417009px;
color: #13BCB4;
margin-top: 22px;
display: block;
}

.trialExpiredModal_featureList__rxy3A {
width: 100%;
height: 43%;
background: #EEF6FD;
border-radius: 0px;
margin-top: 37px;
display: block;
}

.trialExpiredModal_fetaureContainer__2uybw {
  margin-top: 8px;
  margin-left: 10%;
}

.trialExpiredModal_featureText__1xEhW {
font-size: 17px;
line-height: 35px;
letter-spacing: 0.589982px;
color: #74787B;
}

.trialExpiredModal_confirmationContainer__3v_so {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.trialExpiredModal_confirmationText__VDN-p {
font-size: 16.8736px;
line-height: 25px;
text-align: center;
letter-spacing: 0.42184px;
color: #979797;
text-align: center;
display: block;
}

.trialExpiredModal_buttonsContainer__X73Zs {
  display: flex;
  justify-content: space-evenly;
}

.trialExpiredModal_button__JNajF {
  border-radius: 51.7581px;
  width: 41%;
  height: 7%;
  min-height: 52px;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  align-items: center;
  text-align: center;
  outline: none;
  cursor: pointer;
}

.trialExpiredModal_upgradePlan__2OHTJ {
  background: #13BCB4;
  color: white;
  border: none;
}

.trialExpiredModal_downgradePlan__3mAO- {
  background: white;
  border: 2px solid #13BCB4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #13BCB4;
}
/*@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);*/

.inactiveSurvey_overlayContainer__2bwhZ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.inactiveSurvey_container__3pHiQ {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 28.8vw;
  min-width: 480px;
  max-width: 480px;
  /*min-height: 762px;
  height: 78vh;*/
  height: 95%;
  max-height: 820px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
  overflow: auto;
}

.inactiveSurvey_modalContent__2gBxX {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.inactiveSurvey_icon__N0hev {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.inactiveSurvey_image__2C4bA {
    display: block;
    margin-right: auto;
    margin-left: auto;
    border-radius: 16px;
    width: 50%;
}

.inactiveSurvey_header__1Nkj7 {
font-weight: bold;
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
}

.inactiveSurvey_subHeader__34S2g {
  font-size: 16.5px;
  line-height: 25px;
  letter-spacing: 0.41786px;
  color: #323232;
  text-align: left;
  margin-left: 58px;
  margin-top: 30px;
}

.inactiveSurvey_answersContainer__3HELo {
  display: block;
  margin-top: 10px;
}

.inactiveSurvey_answerContainer__3ufuB {

  margin-bottom: 19px;
  margin-left: 58px;
  display: flex;
  align-items: center;
}

.inactiveSurvey_answerText___mFzB {
font-size: 13.5562px;
line-height: 25px;
letter-spacing: 0.419063px;
color: #74787B;
}

.inactiveSurvey_comments__2RBS- {
width: 78%;
max-width: 433px;
height: 119px;
background: #FAFAFA;
border: 1.9877px solid #E1E1E1;
margin-top: 5%;
resize: none;
font-size: 19.5562px;
line-height: 32px;
color: #323232;
margin-left: auto;
margin-right: auto;
display: block;
outline: none;
padding-left: 19px;
padding-top: 13px;
}

.inactiveSurvey_comments__2RBS-::-webkit-input-placeholder {
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 19.5562px;
line-height: 32px;
color: #CBCFD2;
padding: 0;
}

.inactiveSurvey_comments__2RBS-:-ms-input-placeholder {
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 19.5562px;
line-height: 32px;
color: #CBCFD2;
padding: 0;
}

.inactiveSurvey_comments__2RBS-::placeholder {
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 19.5562px;
line-height: 32px;
color: #CBCFD2;
padding: 0;
}

.inactiveSurvey_sendButton__21uM6 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #13BCB4;
  border-radius: 51.7581px;
  width: 227px;
  height: 62.6px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  outline: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
  cursor: pointer;
}

.inactiveSurvey_sendButtonDisabled__1Pgvx {
background: #D7D7D7;
cursor: default;
}

.inactiveSurvey_thankYouContainer__hXe2W {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.inactiveSurvey_heartIcon__3naH0 {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.inactiveSurvey_thankYouTitle__C0ose {
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
}

.inactiveSurvey_notLeaving__2KRoQ {
font-size: 16.5px;
line-height: 29px;
text-align: center;
-webkit-text-decoration-line: underline;
        text-decoration-line: underline;
color: #13BCB4;
margin-top: 10px;
cursor: pointer;
display: block;
margin-bottom: 15px;
width: -moz-fit-content;
width: fit-content;
margin-left: auto;
margin-right: auto;
}
/*@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);*/

.engagedUsersSurvey_overlayContainer__2l1df {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.engagedUsersSurvey_container__V8RkS {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 28.8vw;
  min-width: 480px;
  max-width: 480px;
  min-height: 662px;
  /*height: 78vh;*/
  max-height: 820px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
}

.engagedUsersSurvey_modalContent__FFhxD {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.engagedUsersSurvey_icon__IYEpf {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.engagedUsersSurvey_image__2Kvno {
    display: block;
    margin-right: auto;
    margin-left: auto;
    border-radius: 16px;
    width: 30%;
}

.engagedUsersSurvey_header__11wGe {
font-weight: bold;
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
margin-bottom: 21px;
}

.engagedUsersSurvey_answerText__2V4Og {
font-size: 14.5562px;
line-height: 25px;
letter-spacing: 0.419063px;
color: #74787B;
max-width: 407px;
margin-left: 49px;
display: block;
margin-left: 49px;
margin-right: auto;
}

.engagedUsersSurvey_sendButton__3nQ3v {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #13BCB4;
  border-radius: 51.7581px;
  width: 247px;
  height: 52.6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  outline: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px;
  cursor: pointer;
}

.engagedUsersSurvey_thankYouContainer__2Wyb9 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.engagedUsersSurvey_heartIcon__3LGKf {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.engagedUsersSurvey_thankYouTitle__2QIOl {
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
}

.engagedUsersSurvey_footer__VdswX {
  display: block;
  text-align: center;
  font-size: 14.5562px;
  line-height: 25px;
  letter-spacing: 0.419063px;
  color: #74787B;
  margin-top: 18px;
}

.engagedUsersSurvey_offerContainer__1WhKW {
  background: #E7F8F7;
  border-radius: 0px;
  width: 100%;
  height: 188px;
}

.engagedUsersSurvey_offerContent__2FEGm {
  display: block;
  margin-top: 30px;
  margin-left: 60px;
  position: relative;
  top: 5px;
}

.engagedUsersSurvey_offerHeader__1HXU4 {
font-size: 18px;
line-height: 27px;
letter-spacing: 0.3px;
color: #323232;
}

.engagedUsersSurvey_planFeatureContainer__365nF {
  display: flex;
  margin-top: 10px;
  align-items: baseline;
}

.engagedUsersSurvey_planFeatureText__1T_ds {
font-weight: 300;
font-size: 14px;
line-height: 28px;
letter-spacing: 0.627384px;
color: #323232;
margin-left: 15px;
}

.newExtensionPopup_newExtensionPopup__3OCVV {
  position: fixed;
  top: 23px;
  right: 38px;
  z-index: 9000000000000000000;
  border: none;
  width: 422px;
  height: 528px;
  -webkit-box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.6);
          box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  background-color:white;
}

.newExtensionPopup_newExtensionPopup__3OCVV::before {
  content: "";
  position: fixed;
  top: 16px;
  right: 78px;
  -webkit-transform: rotate(
45deg
);
          transform: rotate(
45deg
);
  border-radius: 2px;
  width: 16px;
  height: 16px;
  background-color:white;
}

.newExtensionPopup_newExtensionContainer__2v_aw {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  justify-content: center;
}

.newExtensionPopup_newExtensionTitle__2SRSx {
  font-weight: bold;
  font-size: 28px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 0.348406px;
  color: #13BCB4;
/*  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.10278px;
  color: #323232;*/
}

.newExtensionPopup_newExtensionDescriptionWrapper__13Jtj {

}

.newExtensionPopup_newExtensionDescription__3td1S {
   font-size: 12.5px;
   line-height: 19px;
   text-align: center;
   color: #FFFFFF;
   margin-top: 24px;
   color: #74787B;
}

.newExtensionPopup_newExtensionImageContainer__3fbwG {
  width: 100%;
  background-color: #E7F8F7;
  display: flex;
  align-items: center;
  min-height: 276px;
  margin-top: 24px;
}

.newExtensionPopup_newExtensionImage__mZ2fR {
  margin-left: auto;
  margin-right: auto;
  border-radius: 13.9101px;
  width: 80%;
}

.newExtensionPopup_extensionIcon__2hzVv {
  margin-left: 6px;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.newExtensionPopup_newExtensionButton__3yNuk {
  width: 155px;
  height: 50px;
  background: #13BCB4;
  border-radius: 42.5878px;
  margin-top: 35px;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  border: none;
  outline: none;
  cursor: pointer;
}

.newExtensionPopup_extensionLogo__2O3dB {
  margin-left: auto;
  margin-right: auto;
}



.utils_heading2Xl__1I65m {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
  }
  
  .utils_headingXl__1XecN {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 800;
    letter-spacing: -0.05rem;
    margin: 1rem 0;
  }
  
  .utils_headingLg__de7p0 {
    font-size: 1.5rem;
    line-height: 1.4;
    margin: 1rem 0;
  }
  
  .utils_headingMd__3de6G {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .utils_borderCircle__13qdJ {
    border-radius: 9999px;
  }
  
  .utils_colorInherit__3Gudf {
    color: inherit;
  }
  
  .utils_padding1px__oCny8 {
    padding-top: 1px;
  }
  
  .utils_list__S7_pe {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .utils_listItem__2eJpJ {
    margin: 0 0 1.25rem;
  }
  
  .utils_lightText__12Ckm {
    color: #999;
  }

  .utils_dropZone__6a-DW {
    align-items: center;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    -webkit-transition: border .24s ease-in-out;
    transition: border .24s ease-in-out;
    position: absolute;
    width: -webkit-fill-available;
    bottom: 0px;
    top: 0;
  }

  .utils_mainWrapper__3So1t {
    height: 100vh;
   /* display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-top: 7vh;*/
  }

  @media screen and (min-width: 768px) {
    .utils_mainWrapper__3So1t {
      margin-top: unset;
    }
  }

  .utils_root__1eP65 {
    height: 100%;
    outline: none;
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
  }

  .utils_poweredByText__2l-o7 {
    font-size: 15px;
    letter-spacing: 0.444977px;
    color:white;
    display: block;
    outline: none;
    border: none;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    .utils_poweredByText__2l-o7 {
      text-align: unset;
    }
  }

  .utils_poweredByLink__v7dWW {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    padding: 0;
    margin: 0;
  }

  .utils_poweredByContainer__19_X8{
    display: block;
    margin-top: auto;
    position: relative;
    padding-top: 10px;
    
  }

  @media screen and (min-width: 768px) {
    .utils_poweredByContainer__19_X8 {
      bottom: 6vh;
      padding-top: unset;
      margin-left: 50px;
    }
  }

  .utils_loaderText__1Man2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    color: #FFFFFF;
    display: block;
    position: relative;
    top: 14px;
  }
  
  .utils_loaderTextSubHeadline__vsfRw {
    font-size: 12px;
  }

  .utils_mainGridContainer__qJRFD {
    height: 100%;
  }
