.container {
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 19px;
height: 400px;
max-width: 280px;
min-width: 280px;
background: white;
display: none;
}

@media screen and (min-width: 768px) {
  .container {
    display: flex;
  }
}

.containerExtended {
  composes: container;
  height: 486px;
  }

.innerContainer {
  height: 100%;
  width: -webkit-fill-available;
}

@media screen and (min-width: 768px) {
  .innerContainer {
    z-index: 1000;
  }
}

.formButton {
  background: #D7D7D7;
  border-radius: 37.2352px;
  width: 131px;
  height: 45px;
  border: none;
  outline: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  bottom: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  min-width: 131px;
}

.formButton:hover{
  background: #0F9790;
}

  .transferButtonContinaer {
  /*  border-top: 1px solid #d4d7d9;*/
  /*  height: 4.5em;*/
    padding: 0 1.25em;
    position: relative;
    padding-top: 10px;
    min-height: 81px;
    display: flex;
    flex-direction: column;
    }
    
    .transferButtonStyle {
    composes: formButton;
    background: #13BCB4;
    margin-top: 12px;
    cursor: pointer;
    }

    .verifyButtonContainer {
      composes: typography from './commonStyles.module.css';
      composes: transferButtonStyle;
      bottom: 2vh;
      right: 4vh;
      }

    .networkErrorButton {
      composes: transferButtonStyle;
      width: 179px;
    }

    .downloadAllFilesStyle {
      composes: formButton;
      background: #13BCB4;
      width: 165px;
      height: 45px;
      cursor: pointer;
      bottom: 0px;
      top: 6px;
      }

    .driveMoreButtonStyle {
    composes: typography from './commonStyles.module.css';
    composes: formButton;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-top: 20px;
    background: #13BCB4;
    font-size: 16px;
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer;
    }

    .addNewFilesButtonStyle {
      composes: transferButtonStyle;
      font-size: 16px;
      width: 179px;
      top: 5px;
      }

    .downloadViewTitle {
      composes: typography from './commonStyles.module.css';
      display: inline-block;
      position: absolute;
      width: 230px;
      height: 22px;
      left: 74px;
      top: 200px;
      font-size: 14px;
      line-height: 16px;
      align-items: center;
      color: #000000;
    }

    .downloadViewCallToAction {
      composes: typography from './commonStyles.module.css';
      position: relative;
      padding-top: 100px;
      font-size: 14px;
      line-height: 16px;
      left: 80px;
      text-decoration: underline;
      display: inline-block;
      align-items: center;
      color: #000000;
      }

      .spinner {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }

      .transferPanelContainer {
       display: flex;
       flex-direction: column;
       /*padding-left: 10px;*/
       padding-top: 5px;
       gap: 6px;
      }

      .cancelPanelContainer {
        display: block;
        padding-left: 10px;
        padding-top: 5px;
        position: relative;
        bottom: 8px;
       }

      .transferPanelHeader {
        composes: typography from './commonStyles.module.css';
        font-size: 15px;
        display: flex;
        align-items: center;
        color: #13BCB4;
        height: 10px;
        white-space: nowrap;
      }

      .cancelTransfer {
        composes: typography from './commonStyles.module.css';
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: 0.3px;
        text-decoration-line: underline; 
        color: #979797;
        cursor: pointer;
        margin-left: 20px;
        position: relative;
        bottom: 3px;
       /* position: relative;
        left: 30px;
        height: 10px;
        bottom: 5px;*/
      }

      .cancelTransferEmail {
        composes: cancelTransfer;
        left: 10px;
      }

      .mbTransferred {
        composes: typography from './commonStyles.module.css';
        font-size: 12px;
        letter-spacing: 0.3px;
        color: #BBBDC0;
        white-space: nowrap;
      } 

      .transferPanelHeaderWrapper {
        display: flex;
      }

      .transferPanelHeaderText {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }

      .confirmCancel {
        composes: typography from './commonStyles.module.css';
        display: block;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.3px;      
        color: #979797;
        padding-bottom: 5px;
      }

      .confirmCancelSelectionChips {
        display: flex;
        justify-content: center;
      }

      .iconUploadFiles {
        transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 96px;
        margin-top: -60px;
        transform: rotate(90deg);
        z-index: 3000;
      }

      .iconAddRecipients {
        transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 287px;
        transform: rotate(180deg);
        z-index: 3000;
        margin-top: 127px;
      }

      .iconClickSend {
        transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 215px;
        transform: rotate(180deg);
        z-index: 3000;
        margin-top: 335px;
      }

      .iconCopyLink {
        transform: translate(-50%,-50%);
        width: 80px;
        height: 60px;
        display: block;
        position: absolute;
        margin-left: 260px;
        transform: rotate(180deg);
        z-index: 3000;
        margin-top: 222px;
      }
      
      .arrow {
        position: absolute;
        top: 25px;
        width: 90%;
        height: 10px;
        background-color: #fff;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
        animation: arrow 700ms linear infinite;
        background: #13BCB4;
      }
      
      .arrow::after, .arrow::before {
        content: '';
        position: absolute;
        width: 60%;
        height: 10px;
        right: -8px;
        background-color:#13BCB4;
      }
      
      .arrow::after {
        top: -12px;
        transform: rotate(45deg);
      }
      
      .arrow::before {
        top: 12px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
        transform: rotate(-45deg);
      }

      .ldsRing {
        width: 20px;
        margin-right: 13px;
        width: 20px;
        margin-right: 13px;
        position: relative;
        right: 9px;
        bottom: 6px;
        /*display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        top: 18px;
        position: relative;
        left: -9px;
        top: 2px;*/
      }
      .ldsRing div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 8px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #13BCB4 transparent transparent transparent;
      }
      .ldsRing div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .ldsRing div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .ldsRing div:nth-child(3) {
        animation-delay: -0.15s;
      }

      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes arrow {
        0% {
          left: 0;
        }
        50% {
          left: 10px;
        }
        100% {
          left: 0;
        }
      }
