.container {
  top: 0.1%;
  background-color: white;
  animation: verificationSlide 0.5s linear 0s;
  display: block;
  position: relative;
  height: 100%;
  border-radius: 16px;
 }

 .containerClosed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1%;
  background-color: white;
  display: none;
 }

.verificationTitle {
  composes: typography from './commonStyles.module.css';
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #323232;
  text-align: center;
  display: block;
}

.verificationText {
composes: typography from './commonStyles.module.css';
font-size: 15px;
line-height: 18px;
text-align: center;
letter-spacing: 0.3px;
color: #74787B;
display: block;
margin-top: 14px;
}

 .sendEmailAgain {
  composes: typography from './commonStyles.module.css';
  display: block;
  text-align: center;
  margin-top: 18px;
  color: #13BCB4;
  cursor: pointer;;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
 }

 .emailSentImage {
   display: block;
   margin-left: auto;
   margin-right: auto;
 }

 .resendButtonDisabled {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: #D7D7D7;
  margin-top: 68px;
  width: 131px;
  height: 45px;
  border: none;
  outline: none;
  border-radius: 37.2352px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
 }

 .resendButton {
  composes: resendButtonDisabled;
  background: #13BCB4;
  cursor: pointer;
  position: relative;
  margin-top: 60px;
 }

 .resendButton:hover{
  background: #0F9790;
 }

 .resendButton:hover{
  background: #0F9790;
 }

 .linkIcon {
  position: relative;
  top: 18px;
  left: 70px;
 }

 .getLink {
  display: flex;
  position: relative;
  right: 5px;
}

.getLinkText {
  composes: typography from './commonStyles.module.css';
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2vh;
  text-align: center;
  color: #13BCB4;
  cursor: pointer;;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
}

 @keyframes slidein {
  from { width: 0%; } to { width: 100%; }
}

@keyframes verificationSlide {
  from { margin-top: 20%; } to { margin-top: 0%; }
}
