.container {
    composes: typography from './commonStyles.module.css';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden; 
    text-align: center;
}

.clockIcon {
    max-width: 23%;
}

.text {
composes: typography from './commonStyles.module.css';
font-weight: 600;
font-size: 90%;
line-height: 29px;
text-align: center;
letter-spacing: 0.568145px;
color: #13BCB4;
}