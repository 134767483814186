/*@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);*/

.overlayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  border-radius: 0px 0px 8px 8px;
}

.container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 28.8vw;
  min-width: 480px;
  max-width: 480px;
  /*min-height: 762px;
  height: 78vh;*/
  height: 95%;
  max-height: 820px;
  background: #FFFFFF;
  box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  z-index: 2000;
  overflow: auto;
}

.modalContent {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}

.icon {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.image {
    display: block;
    margin-right: auto;
    margin-left: auto;
    border-radius: 16px;
    width: 50%;
}

.header {
composes: typography from './commonStyles.module.css';
font-weight: bold;
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
}

.subHeader {
  composes: typography from './commonStyles.module.css';
  font-size: 16.5px;
  line-height: 25px;
  letter-spacing: 0.41786px;
  color: #323232;
  text-align: left;
  margin-left: 58px;
  margin-top: 30px;
}

.answersContainer {
  display: block;
  margin-top: 10px;
}

.answerContainer {

  margin-bottom: 19px;
  margin-left: 58px;
  display: flex;
  align-items: center;
}

.answerText {
composes: typography from './commonStyles.module.css';
font-size: 13.5562px;
line-height: 25px;
letter-spacing: 0.419063px;
color: #74787B;
}

.comments {
width: 78%;
max-width: 433px;
height: 119px;
background: #FAFAFA;
border: 1.9877px solid #E1E1E1;
margin-top: 5%;
resize: none;
composes: typography from './commonStyles.module.css';
font-size: 19.5562px;
line-height: 32px;
color: #323232;
margin-left: auto;
margin-right: auto;
display: block;
outline: none;
padding-left: 19px;
padding-top: 13px;
}

.comments::placeholder {
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 19.5562px;
line-height: 32px;
color: #CBCFD2;
padding: 0;
}

.sendButton {
  composes: typography from './commonStyles.module.css';
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #13BCB4;
  border-radius: 51.7581px;
  width: 227px;
  height: 62.6px;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  outline: none;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
  cursor: pointer;
}

.sendButtonDisabled {
composes: sendButton;
background: #D7D7D7;
cursor: default;
}

.thankYouContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
}

.heartIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.thankYouTitle {
composes: typography from './commonStyles.module.css';
font-size: 30px;
line-height: 42px;
text-align: center;
letter-spacing: 0.417009px;
color: #323232;
display: block;
margin-top: 24px;
}

.notLeaving {
composes: typography from './commonStyles.module.css';
font-size: 16.5px;
line-height: 29px;
text-align: center;
text-decoration-line: underline;
color: #13BCB4;
margin-top: 10px;
cursor: pointer;
display: block;
margin-bottom: 15px;
width: fit-content;
margin-left: auto;
margin-right: auto;
}