.container {
    composes: typography from './commonStyles.module.css';
    width: 100vw;
    z-index: 1000;
    color: #fff;
    opacity: 0.5;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    display: flex;
    align-items: center;
    grid-area: footer;
    justify-content: center;
}

@media screen and (min-width: 769px) {
  .container {
    justify-content: unset;
    /* position: absolute;
    text-align: unset;
    position: unset;
   padding-bottom: 5vh;
    max-width: 15vw;*/
  }
}

.footerLink {
  composes: typography from './commonStyles.module.css';
  color: #13BCB4;
 /* color: #fff;
  opacity: 0.5;*/
  margin-left: 0;
  margin-right: auto;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  display: inline-block;
  padding: 0;
  margin-right: 5vw;
}

@media screen and (min-width: 769px) {
  .footerLink {
    margin-right: 2vw;
  }
}

.innerContainer {
  display: inline-block;
  /*padding-left: 43px;
  width: inherit;*/
}

@media screen and (min-width: 769px) {
  .innerContainer {
    width: unset;;
    padding-left: 43px;
  }
}

@media screen and (min-width: 769px) {
  .footerLink {
    margin-right: 2vw;
  }
}