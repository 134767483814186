.recipientsList {
    composes: typography from './commonStyles.module.css';
    composes: inputTextColor from './commonStyles.module.css';
    min-height: 46px;
    font-size: 14px;
    line-break: 23px;
    left: 40px;
    outline: none;
    border: none;
    margin-bottom: 4px;
    width: 100%;
    border-bottom: 1px solid rgb(0, 0, 0, 0.15);
}

