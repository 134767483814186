.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EEF6FD;
    opacity: 0.5;
    width: 280px;
    height: 174px;
    z-index: 50;
    position: absolute;
    border-radius: 19px;
  }