.container {
    box-sizing: border-box;
    overflow-x: hidden; 
}

.verifyEmailTitle {
composes: typography from './commonStyles.module.css';
font-size: 16px;
line-height: 23px;
color: #000000;
position: relative;
bottom: 70px;
}

.lockStyle {
   left: 64px;
   position: relative;
   bottom: 50px;
   width: 150px;
   text-align: center;
}

.sendAgainText {
    composes: typography from './commonStyles.module.css';
    display: inline;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

.varificationText {
    composes: typography from './commonStyles.module.css';
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    position: relative;
    text-align: center;
    bottom: 70px;
} 

.errorText {
    composes: typography from './commonStyles.module.css';
    max-width: 220px;
    font-size: 12px;
    line-height: 16px;
    display: -webkit-flex;
    display: flex;
    color: red;
    text-align: center;
    position: relative;
    top: 10px;
    left: 55px;
} 

.input {
    composes: typography from './commonStyles.module.css';
    width: 241px;
    height: 43px;
    background: #FFFFFF;
    border: 1px solid #D0D4D9;
    box-sizing: border-box;
    border-radius: 12px;
    position: relative;
    bottom: 50px;
    padding-bottom: 20p;
    outline: none;
    text-align: center;
    font-size: 16px;
    line-break: 23px;
}

.inputplaceHolder {
    composes: typography from './commonStyles.module.css';
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    opacity: 0.4;
}
