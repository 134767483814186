.gridContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 0% 1fr 7%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  grid-template-areas:
      "header"
      "homePageContainer"
      "footer";
  ;
}

.homePageContainer {
  width: 100%;
  grid-area: homePageContainer;
}

.homePageContainerFlex {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  grid-area: homePageContainer;
}

.formWrapper {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.formWrapperFlex {

}

.mainContentOuterWrapper {
  display: block;
  z-index: 2000;
}


.poweredByText {
  composes: typography from './commonStyles.module.css';
  font-size: 15px;
  letter-spacing: 0.444977px;
  color:white;
  display: block;
  outline: none;
  border: none;
}

.poweredByLink {
  composes: poweredByText;
  text-decoration-line: underline;
  padding: 0;
  margin: 0;
}

.poweredByContainer{
  display: block;
  display: block;
  margin-left: 50px;
  margin-top: 3vh;
}

@media screen and (min-width: 768px) {
  .homePageContainer {
    flex-flow: row nowrap; /* required */
  }
  .formWrapper {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    flex: none;
}

.formWrapperFlex {
  display: flex;
  box-sizing: border-box;
  width: fit-content;
  position: relative;
  bottom: 9vh;
  left: 50px;
}
  .mainContentOuterWrapper {
    z-index: 100;
  }
  .homePageContainerFlex {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: unset;
  }
}