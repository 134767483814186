.container {
    composes: typography from './commonStyles.module.css';
    width: 100%;
    height: 126px;
    z-index: 1000;
    display: flex;
    position: absolute;
    bottom: 0;
    /*margin-top: auto;*/
    position: absolute;
    bottom: 0px;
    background: #FFFFFF;
    border: 2.08267px solid #EBEBEB;
    box-sizing: border-box;
    box-shadow: 0px 12.496px 41.6535px rgba(0, 0, 0, 0.1);
    border-radius: 21.7455px 21.7455px 0px 0px;
}

@media screen and (min-width: 769px) {
  .container {
     /*margin-top: auto;
   padding-bottom: 5vh;
    max-width: 15vw;*/
  }
}

.footerLink {
  composes: typography from './commonStyles.module.css';
  color: #13BCB4;
 /* color: #fff;
  opacity: 0.5;*/
  margin-left: 0;
  margin-right: auto;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  display: inline-block;
  padding: 0;
  margin-right: 5vw;
}

@media screen and (min-width: 769px) {
  .footerLink {
    margin-right: 2vw;
  }
}

.innerContainer {
  display: flex;
  padding-left: 5vw;
  position: relative;
  top: 25px;
  height: 0;
}

@media screen and (min-width: 769px) {
  .footerLink {
    margin-right: 2vw;
  }
}

.ctaHeaderContainer {
  display: block;
  position: relative;
  top: 15px;
  height: 0;
}

.ctaHeader {
composes: typography from './commonStyles.module.css';
font-weight: bold;
font-size: 34px;
letter-spacing: -0.244445px;
color: #323232;
}

.ctaSubHeader {
composes: typography from './commonStyles.module.css';
font-size: 16px;
line-height: 30px;
display: flex;
align-items: center;
letter-spacing: 0.3px;
color: #323232;
}

.ctaLink {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 175px;
  height: 45px;
  background: #13BCB4;
  border-radius: 37.2352px;
  margin-top: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  top: 18px;
  left: 71px;
}

.ctaLink:hover {
  background: #0F9790;
}

.ctaLinkText {
  composes: typography from './commonStyles.module.css';
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  padding-left: 10px;
  color: #FFFFFF;
}