.upgradeMessageContainer {
  min-width: 44.6vw;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  grid-area: upgradeMessage;
}

.limitReachedMessageContainer {
  composes: upgradeMessageContainer;
  background: #EA3989;
  gap: unset;
  border-radius: 16px;
}

.limitReachedFlexWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.limitReachedText {
composes: typography from './commonStyles.module.css';
font-weight: 500;
font-size: 16px;
line-height: 22px;
letter-spacing: 0.3px;
color: #FAFAFA;
margin-left: 0.77%;
margin-right: 0.4%;
}

.limitReachedUpgradeLink {
  composes:limitReachedText;
  margin: unset;
  padding: unset;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
}

.limitReachedResetDate {
  composes: limitReachedText;
  font-weight: 300;
  margin: unset;
}

.bellIcon {
  
}

.transfersLeftContainer {
  display: flex;
  flex-direction: column;
  min-height: 58%;
  justify-content: space-evenly;
}

.textFlexWrapper {
  display: flex;
  gap: 6px;
}

 .resetDate {
 composes: typography from './commonStyles.module.css';
 font-weight: 300;
 font-size: 12px;
 line-height: 21px;
 letter-spacing: 0.3px;
 color: #FFFFFF;
 opacity: 0.5;
 }

.upgradeMessageText {
  composes: typography from './commonStyles.module.css';
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
}

.progressBar {
  background: #181C45;
  border-radius: 30px;
  height: 6px;
}

.progressFiller {
  composes: progressBar;
  background:  #13BCB4;
  transform: rotate(-180deg);
  max-width: 100%;
}

.progressFillerYellow {
  composes: progressFiller;
  transform: unset;
  background: #F5BE58;
  /*background: linear-gradient(90deg, #13BCB4 3.44%, #F5BE58 90.17);*/
 
}

.progressFillerRed {
  composes: progressFiller;
  /*background: linear-gradient(90deg, #EA3989 12.44%, #13BCB4 63.17%);*/
  background-color: #EA3989;
}

.upgradeButton {
  composes: typography from './commonStyles.module.css';
  background: inherit;
  border: 2.5px solid #EA3989;
  border-radius: 46.544px;
  height: 45px;
  min-width: 171px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #EA3989;
  outline: none;
  cursor: pointer;
  padding-left: 8%;
  padding-right: 8%;
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .upgradeMessageContainer {
    min-width: 44.6vw;
    height: 62px;
    min-height: unset;
    top: 66px;
    box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
    background: #252852;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
    grid-area: upgradeMessage;
  }

  .limitReachedMessageContainer {
    composes: upgradeMessageContainer;
    background: #EA3989;
    gap: unset;
    border-radius: unset;
  }

  .transfersLeftContainer {
    display: flex;
    flex-direction: column;
    min-height: 58%;
    justify-content: space-between;
    margin-left: 4%;
  }

  .upgradeButton {
    composes: typography from './commonStyles.module.css';
    padding: unset;
    background: #EA3989;
    border-radius: 37.2352px;
    height: 38px;
    min-width: 258px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    outline: none;
    border: none;
    cursor: pointer;
    margin-right: 4%;
  }

  .upgradeMessageText {
    font-size: 16px;
  }

  .resetDate {
    font-size: 14px;
  }
}