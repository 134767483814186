.item {
    composes: typography from './commonStyles.module.css';
    background-color: #d4d5d6;
    font-size: 14px;
    border-radius: 30px;
    height: 24px;
    padding: 0 6px 0 .4rem;
    display: inline-block;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    opacity: 0.8;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
    text-align: -webkit-right;
    letter-spacing: 0.5px;
    outline: none;
  }
  
  .button {
    background-color: white;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    outline: none;
  }