.floatingLabeTextArea {
    composes: typography from './commonStyles.module.css';
    composes: inputTextColor from './commonStyles.module.css';
    font-size: 14px;
    line-break: 23px;
    /*position: absolute;*/
    left: 40px;
    transition: all 500ms;
    outline: none;
    border: none;
    width: 100%;
}

.label {
    composes: typography from './commonStyles.module.css';
    composes: inputTextColor from './commonStyles.module.css';
    font-size: 14px;
    line-break: 23px;
    /*position: absolute;*/
    left: 40px;
    opacity: 0.4;
    transition: all 500ms;
    pointer-events: none;
    position: relative;
    transform: translate(0, 26px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
    display: flex;
    flex-direction: column;
    left: 0px;
    bottom: 13.5px;
    padding-bottom: 10px;
}

