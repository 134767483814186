.fileName {
    composes: typography from './commonStyles.module.css';
    width: 200px;
    height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 146.48%;
    text-align: left;
}

.fileSize {    
    composes: typography from './commonStyles.module.css';
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    opacity: 0.4;
    width: -webkit-fill-available;
    padding-bottom: 5px;
    height: 10px;
    margin: 0px;
}

.uploadProgress {    
  composes: typography from './commonStyles.module.css';
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
  opacity: 0.4;
  width: -webkit-fill-available;
  padding-bottom: 5px;
  height: 10px;
  margin: 0px;
}

.listItemStyle {
    width: -webkit-fill-available;
    height: 19px;
    left: -20px;
    display: table;
    right: 20px;
    padding-right: 10px;
    position: relative;
    overflow-wrap: break-word;
    border-bottom: 1px solid rgb(0, 0, 0, 0);
}

.listItemStyleInProgress {
  composes: listItemStyle;
  opacity: 0.3;
}

.li {
    white-space: nowrap;
    display: table-row;
    width: 240px;
    height: 19px;
    left: 23px;
    top: 26px;
    position: absolute;
}

.spinner {
  position: absolute;
  left: 200px;
  top: 50%;
  transform: translateY(-50%);
}

.downloadLink {
  font-size: 12px;
  padding-top: 16px;
  margin: 0;
  outline: none;
  right: 18px;
}

.downloadIcon {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  outline: none;
  position: relative;
  left: 14px;
  top: 15px;
}

.spinner {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 214px;
  top: 24px;
}

/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

 .CircularProgressbar {
    /*
     * This fixes an issue where the CircularProgressbar svg has
     * 0 width inside a "display: flex" container, and thus not visible.
     */
     left: 210px;
     position: absolute;
     top: 15px;
     width: 64px;
     height: 64px;
    /*
     * This fixes a centering issue with CircularProgressbarWithChildren:
     * https://github.com/kevinsqi/react-circular-progressbar/issues/94
     */
    vertical-align: middle;
  }
  
  .CircularProgressbar .CircularProgressbar-path {
    stroke: #3e98c7;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  
  .CircularProgressbar .CircularProgressbar-trail {
    stroke: #d6d6d6;
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
    stroke-linecap: round;
  }
  
  .CircularProgressbar .CircularProgressbar-text {
    fill: #3e98c7;
    font-size: 32px;
    dominant-baseline: middle;
    text-anchor: middle;
    font-weight: 600;
  }
  
  .CircularProgressbar .CircularProgressbar-background {
    fill: #d6d6d6;
  }
  
  /*
   * Sample background styles. Use these with e.g.:
   *
   *   <CircularProgressbar
   *     className="CircularProgressbar-inverted"
   *     background
   *     percentage={50}
   *   />
   */
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
    fill: #3e98c7;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
    fill: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
    stroke: #fff;
  }
  
  .CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
    stroke: transparent;
  }

