.addBrandCtaWrapper {
  display: block;
}

.addBrandCtaContent {
  display: flex;
  align-items: flex-end;
  min-width: 800px;
  margin-top: 4%;
}

.addBrandCtaContentWrapper {
  display: block;
}

.addBrandCtaRight {
  display: block;
  margin-left: 40px;
  width: 327px;
  text-align: left;
  position: relative;
  bottom: 10px;
}

.brandPreviewImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 431px;
  max-height: 326px;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.addBrandCtaText {
  composes: typography from './commonStyles.module.css';
  width: max-content;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  max-width: 297px;
  position: relative;
  top: 10px;
}

.addBrandCtaTextBold {
  composes: typography from './commonStyles.module.css';
  width: max-content;
  display: block;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 40px;
}

.buttonContainer {
  display: block;
}

.addBrandButton {
  composes: typography from './commonStyles.module.css';
  min-width: 208px;
  width: fit-content;
  height: 43px;
  background: #13BCB4;
  border-radius: 49.3593px;
  display: block;
  border: none;
  outline: none;
  color:  white;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 33px;
  margin-top: 28px;
  cursor: pointer;
  min-width: 144px;
  text-align: center;
  display: block;
  padding-top: 5px;
  margin-left: 0;
  position: relative;
  top: 10px;
}

.skipBrand {
  composes: typography from './commonStyles.module.css';
  margin-top: 18px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  text-decoration-line: underline;
  color: #13BCB4;
  display: block;
  cursor: pointer;
  position: relative;
  left: 57px;
}

.extensionIcon {

}

.buttonTextWrapper {
  display: flex;
  align-items: center;
}

.buttonText {
composes: typography from './commonStyles.module.css';
font-weight: 600;
font-size: 21.1204px;
line-height: 32px;
align-items: center;
color: #FFFFFF;
margin-left: 8px;
}