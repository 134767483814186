.container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.EmailVerifiedText {
  composes: typography from './commonStyles.module.css';
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  line-height: 42px;
  letter-spacing: 0.3px;
  color: #323232;
  position: relative;
  bottom: 8px;
  margin-bottom: 10px;
}

.header {
  display: block;
  border-bottom: 1px solid #E6E9EF;
  padding-left: 42px;
  width: 100%;
}

.headerTitle {
  display: inline-flex;
}

.verifiedEmail {
  composes: typography from './commonStyles.module.css';
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #74787B;
  position: relative;
  bottom: 15px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

 .emailVerifiedContainer {
   display: block;
   margin-left: auto;
   margin-right: auto;
   position: relative;
   top: 8vh;
 }