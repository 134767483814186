.container {
    box-sizing: border-box;
   /* height: 100%;*/
    overflow-x: hidden; 
    text-align: center;
}

.expiredIcon {
width: 150px;
margin-left: 65px;
}

.summaryTitle {
composes: typography from './commonStyles.module.css';
margin-top: 30px;
font-size: 14px;
line-height: 16px;
display: inline-block;
align-items: center;

color: #000000;
}