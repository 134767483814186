.container {
    box-sizing: border-box;
   /* height: 100%;*/
    overflow-x: hidden; 
    text-align: center;
}

.doneImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 43px;
}

.summaryTitle {
    composes: typography from './commonStyles.module.css';
    display: inline-block;
    align-items: center;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #323232;
    padding-top: 18px;
}

.summaryText {
    composes: typography from './commonStyles.module.css';
    display: inline-block;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #74787B;
    padding-top: 8px;
}

.downloadLink {
    composes: typography from './commonStyles.module.css';
    font-size: 14px;
    line-height: 16px;
    left: 2.5rem;
    text-decoration: underline;
    display: contents;
    align-items: center;
    color: #000000;
    }

    .recipientsList {
        display: grid;
        border: 1px solid #E6E9EF;
        border-right: 0;
        border-left: 0;
    }

    .listItem {
        display: inline-flex;
        min-height: 46px;
        border-bottom: 1px solid #E6E9EF;
    }

    .listItemContent{
        padding-top: 6px;
    }

    .listItemEmail {
    composes: typography from './commonStyles.module.css';
    display: contents;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.3px;    
    color: #74787B;
    }

    .listItemLast {
        composes: listItem;
        border-bottom: 0;
    }

    .linkBoxContainer {
        display: flex;
        justify-content: center;
        padding-top: 48px;
    }

    .linkText {
        composes: typography from './commonStyles.module.css';
        font-size: 12px;
        line-height: 31px;
        letter-spacing: 0.425357px;
        color: #CBCFD2;
        padding-left: 34px;
    }

    .linkBox {
        composes: linkText;
        width: 184px;
        height: 34px;
        display: flex;
        background: #FFFFFF;
        border: 1.42296px solid #E1E1E1;
        outline: none;
        background: url(../public/images/linkIcon.svg) no-repeat right center;
        background-position-x: 12px;
        background-position-y: 50%;
    }

    .linkBox::selection {
        background:rgb(0,0,0,0.6); /* WebKit/Blink Browsers */
      }
      .linkBox::-moz-selection {
        background:rgb(0,0,0,0.6); /* Gecko Browsers */
      }

      .linkBoxCopied {
        composes: linkBox;
        color: #13BCB4;
        padding-left: unset;
        padding-right: 16px;
        text-align: right;
    }

    .copyLinkButton {
        width: 39px;
        height: 34px;
        background: #13BCB4;
        border-radius: 5.89091px;
        cursor: pointer;
        border: none;
        position: relative;
        right: 4px;
        outline: none;
        transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    }

    .copyLinkButton:before, .copyLinkButton:after {
        position: absolute;
        content: '';
        display: block;
        width: 140%;
        height: 100%;
        left: -20%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
      }

    .copyLinkButton:before {
        display: none;
        top: -75%;
        background-image: radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 20%, #13BCB4 20%, transparent 30%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%);
        background-size: 20% 20%, 20% 20%, 25% 25%, 30% 30%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
        animation: topBubbles ease-in-out 0.75s infinite;
      }
      
      .copyLinkButton:after {
        display: none;
        top: -75%;
        background-image: radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, transparent 10%, #13BCB4 15%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%), radial-gradient(circle, #13BCB4 20%, transparent 20%);
        background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
        animation: bottomBubbles ease-in-out 0.75s infinite;
      }

      .copyLinkButtonAnimate {
        composes: copyLinkButton;
      }

      .copyLinkButtonAnimate:before, .copyLinkButtonAnimate:after {
        display: block;
      }

    .linkBoxContent {
        display: flex;
        margin-left: 22px;
    }

    .divider {
        border-top: 1.415px solid #E6E9EF;
        position: relative;
        top: 21px;
    }

      @keyframes
      topBubbles {  0% {
       background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
      }
       50% {
       background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
      }
       100% {
       background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
       background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
      }

      @keyframes
      bottomBubbles {  0% {
       background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
      }
       50% {
       background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
      }
       100% {
       background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
       background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
      }
      }