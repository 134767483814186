.newExtensionPopup {
  position: fixed;
  top: 23px;
  right: 38px;
  z-index: 9000000000000000000;
  border: none;
  width: 422px;
  height: 528px;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  background-color:white;
}

.newExtensionPopup::before {
  content: "";
  position: fixed;
  top: 16px;
  right: 78px;
  transform: rotate(
45deg
);
  border-radius: 2px;
  width: 16px;
  height: 16px;
  background-color:white;
}

.newExtensionContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  justify-content: center;
}

.newExtensionTitle {
  composes: typography from './commonStyles.module.css';
  font-weight: bold;
  font-size: 28px;
  line-height: 64px;
  text-align: center;
  letter-spacing: 0.348406px;
  color: #13BCB4;
/*  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  letter-spacing: -0.10278px;
  color: #323232;*/
}

.newExtensionDescriptionWrapper {

}

.newExtensionDescription {
   composes: typography from './commonStyles.module.css';
   font-size: 12.5px;
   line-height: 19px;
   text-align: center;
   color: #FFFFFF;
   margin-top: 24px;
   color: #74787B;
}

.newExtensionImageContainer {
  width: 100%;
  background-color: #E7F8F7;
  display: flex;
  align-items: center;
  min-height: 276px;
  margin-top: 24px;
}

.newExtensionImage {
  margin-left: auto;
  margin-right: auto;
  border-radius: 13.9101px;
  width: 80%;
}

.extensionIcon {
  margin-left: 6px;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.newExtensionButton {
  composes: typography from './commonStyles.module.css';
  width: 155px;
  height: 50px;
  background: #13BCB4;
  border-radius: 42.5878px;
  margin-top: 35px;
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
  border: none;
  outline: none;
  cursor: pointer;
}

.extensionLogo {
  margin-left: auto;
  margin-right: auto;
}


