.productTutorialContainer {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.tutorialStepContainer {
  display: block;
  margin-left: 49.5px;
  width: max-content;
}

.tutorialStep{
  align-items: center;
  justify-content: center;
  display: flex;
  width: 13vw;
  max-width: 253.37px;
  height: 192.09px;
  background: #252852;
  border-radius: 18.8555px;
  min-width: 187px;
}

.currentTutorialStep {
  composes: tutorialStep;
  border: 1.17847px solid #13BCB4;
  box-sizing: border-box;
}

.stepImage {
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  height: 100%;
}

.stepImageUploadFiles {
  width: 80%;
  background: white;
  border-radius: 11px;
  margin-left: auto;
  margin-right: auto;
  max-width: 140px;
  display: flex;
  justify-content: center;
}

.stepImageUploadFilesLink {
  display: flex;
  justify-content: center;
  width: 80%;
  background:white;
  border-radius: 11px;
  height: 90%;
  max-width: 140px;
}

.stepImageAddRecipients {
  display: flex;
  justify-content: center;
  background:white;
  width: 80%;
  height: 90%;
  border-radius: 11px;
  max-width: 140px;
}

.stepImageClickSend {
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 11px;
  max-width: 140px;
}

.stepTextContainer {
  display: flex;
  margin-top: 17px;
}

.stepText {
  composes: typography from './commonStyles.module.css';
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.491431px;
  color: #FFFFFF;
  margin-left: 20px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  .stepText {
    font-size: 16px;
}
}

.completedStep {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42.2px;
  height: 42.2px;
  border-radius: 50%;
  border: 2.31237px solid #13BCB4;
  box-sizing: border-box;
  background: #13BCB4;
}

.checkMark {
  border-width: 3px;
  height: 18px;
  width: 11px;
  border-color: #181C45;
  display: inline-block;
  transform: rotate(45deg);
  border-bottom: 3px solid #181C45;
  border-right: 3px solid #181C45;
  position: relative;
  bottom: 3px;
}