.homePageContainerEmbedded {
  display: grid;
  grid-template-columns: 15% 1fr 15%;
  grid-template-rows:10.5%;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  grid-template-areas:
      "header header header"
      ". homePageWrapper ."
      ". upgradeMessage ."
      ". footer ."
      ;
  }

  .homePageContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 6.7% 4% 1fr;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header"
        "."
        "homePageWrapper"
        "upgradeMessage"
        "footer";
  }

  .pageTitle {
    composes: typography from './commonStyles.module.css';
    grid-area: pageTitle;
    font-weight: bold;
    font-size: 2em;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    display: none;
  }

  .homePageWrapper {
    display: flex;
    grid-area: homePageWrapper;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .centerContent {
    grid-area: centerContent;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    min-width: 54vw;
  }

  .formWrapper {
    grid-area: formWrapper;
  }

  .mainContentOuterWrapper {
    display: block;
  }


  .poweredByText {
    composes: typography from './commonStyles.module.css';
    font-size: 15px;
    letter-spacing: 0.444977px;
    color:white;
    display: block;
    outline: none;
    border: none;
  }

  .poweredByLink {
    composes: poweredByText;
    text-decoration-line: underline;
    padding: 0;
    margin: 0;
    text-align: center;
    color: #13BCB4;
  }

  .poweredByContainer{
    display: block;
    display: block;
    margin-top: 3vh;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .content {
    display: flex;
    width: 100%;
    flex-wrap: wrap-reverse;
    align-items: center;
  }

  .itemInvisible {
    display: none;
  }

  .minShrinkInvisibleDiv {
    display: none;
  }

  .invisiblePlaceholder {
    height: 62px;
  }

  .defaultDownloadContainer {
    composes: homePageContainer; 
  }

  .embeddedMinimizedContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:1fr;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header"
        "homePageWrapper"
        "footer"
        ;
  }

  .embeddedWithoutUpgradeContainer {
    display: grid;
    grid-template-columns: 12% 1fr 12%;
    grid-template-rows: 10.5%;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header header header"
        ". homePageWrapper .";
  }


  @media screen and (min-width: 768px) {
    .homePageContainer {
      display: grid;
      grid-template-columns: 3vw 1fr;
      grid-template-rows: minmax(60px,8%) minmax(62px,8.8vh) 7% 4% 1fr 7%;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      grid-template-areas:
          "header header"
          "upgradeMessage upgradeMessage "
          ". ."
          "pageTitle pageTitle"
          ". homePageWrapper"
          "footer footer";
      ;
    }

    .tutorialContainer {
      display: grid;
      grid-template-columns: 3% 1fr;
      grid-template-rows:minmax(60px,8%) 17% 1fr 14% 7%;
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      grid-template-areas:
      "header header"
      ". ."
      ". homePageWrapper"
      ". ."
      "footer footer";
    }

    .celebrationContainer {
      composes: tutorialContainer;
      grid-template-columns: 3vw 1fr 30.5vw;
      grid-template-areas:
      "header header header"
      ". . ."
      "pageTitle pageTitle pageTitle"
      ". homePageWrapper ."
      "footer footer footer";
    }

  .homePageContainerPaid {
    display: grid;
    grid-template-columns: 3vw 1fr;
    grid-template-rows: minmax(60px,8%) 10% 4% 1fr 7%;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    grid-template-areas:
        "header header"
        ". ."
        "pageTitle pageTitle"
        ". homePageWrapper"
        "footer footer";
    ;
  }

  .homePageWrapper {
    flex-direction: unset;
    justify-content: unset;
    align-items: center;
    height: fit-content;
    margin-top: unset;
    gap: 5vw;
  }

  .tutorialPageWrapper {
    composes: homePageWrapper;
    gap: 8%;
  }

  .celebrationPageWrapper {
    composes: homePageWrapper;
    align-items: center;
    gap: 6vw;
  }

  .brandPreviewWrapper {
    composes: homePageWrapper;
  }

  .defaultDownloadContainer {
    grid-template-columns: 3vw 1fr 15%;
    grid-template-rows: minmax(60px,8%) 3.8% 1fr 7%;
    grid-template-areas:
        "header header header"
        ". . ."
        ". homePageWrapper ."
        "footer footer footer";
  }

  .defaultDownloadPageWrapper {
    composes: homePageWrapper;
    align-items: center;
    gap: unset;
  }

  .defaultDownloadPageCenterContent {
    composes: centerContent;
    flex-grow: 1;
  }

  .poweredByLink {
    text-align: unset;
    }

    .poweredByContainer {
      width: unset;
      margin-right: unset;
    }

    .content {
      flex-wrap: unset;
      justify-content: space-between;
      align-items: unset;
      height: 100%;
    }

    .itemInvisible {
      display: unset;
      flex: 0 1 auto;
      width: 520px;
      height: 100px;
      visibility: hidden;
    }

    .minShrinkInvisibleDiv {
      display: unset;
      flex: 1 0 40px;
  }

  .pageTitle {
    display: unset;
  }

  }
