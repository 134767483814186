@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900italic,3300,400,500,600,700,800,900);

  .nav {
    composes: typography from './commonStyles.module.css';
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    font-weight: bold;
    text-align: center;
    width: auto;
    height: 6.7vh;
    /*padding-left: 10px;*/
}

.logo {
  composes: typography from './commonStyles.module.css';
  position: relative;
  bottom: 12px;
  display: inline-block;
  width: 0vh;
  height: 0vh;
  padding-top: 45px;
  margin-left: 57px;
  border: none;
  outline: none;
  z-index: 1;
  text-decoration: none !important;
  font-weight: bold;
  font-size: 28px;
  line-height: 74px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #13BCB4;
}

.linkStyle {
  padding: 2%;
  margin: 0;
}

.linkStyleExpanded {
  composes: linkStyle;
  /*position: relative;
  left: 20px;*/
}

.headerContainer {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  width: auto;
  max-width: 290px;
  display: flex;
  align-items: center;
}

.expandContainer {
  display: block;
}

.expand {
  border-width: 3px;
  height: 13px;
  width: 13px;
  border-color: #13BCB4;
  display: inline-block;
  transform: rotate(45deg);
  border-bottom: 1.5px solid #13BCB4;
  border-right: 1.5px solid #13BCB4;
  position: relative;
  bottom: 5px;
  left: 23px;
  cursor: pointer;
}

.minimize {
  border-width: 3px;
  height: 13px;
  width: 13px;
  border-color: #13BCB4;
  display: inline-block;
  transform: rotate(225deg);
  border-bottom: 1.5px solid #13BCB4;
  border-right: 1.5px solid #13BCB4;
  position: relative;
  top: 4px;
  left: 65px;
  cursor: pointer;
}

.infoTooltipContainerWrapper {
  display: block;
  /*position: relative;
  bottom: 6px;
  top: 22px;*/
  position: absolute;
  
}

.infoTooltipContainerWrapperMinimize {
  composes: infoTooltipContainerWrapper;
  margin-left: 11.5px;
  top: 23px;
}

.infoTooltipContainerWrapperExpand{
  composes: infoTooltipContainerWrapper;
  margin-left: -32.5px;
}

.infoTooltipContainer {
width: 119.81px;
height: 47.98px;
background: #32366D;
box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
border-radius: 16px;
display: flex;
justify-content: center;
align-items: center;
}

.infoTooltipText {
composes: typography from './commonStyles.module.css';
font-size: 14px;
line-height: 21px;
text-align: center;
letter-spacing: 0.3px;
color: #FFFFFF;
}

.infoTooltipPointer {
  width: 0;
  height: 0;
  background: transparent;
  position: relative;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #32366D;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transform: rotate(180deg)
}

.closeIcon {
  cursor: pointer;
}

.closeIconExpanded {
  composes: closeIcon;
  position: relative;
  left: 85px;
}

.closeIconMinimized {
  composes: closeIcon;
  position: relative;
  left: 39px;
}

.dragIconExpanded {
  cursor: grab;
  position: relative;
  right: 255px;
  top: 2px;
}

.dragIconMinimized {
  cursor: grab;
  position: relative;
  right: 215px;
  top: 2px;
}



